<template>
  <div id="app">
      <router-view />
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'

export default {
  name: 'Home',
  data () {
    return {
      active: 0
    }
  },
  components: {

  },
  mounted () {
    if (this.$cookies.get('_business_card_user_token')) {
      axios
        .get('/user/login')
        .then(res => {
          console.log(res)

          if (res.data.returnCode === '0') {
            this.$store.commit('setUserInfo', res.data.userInfo)
          } else if (res.data.returnCode === '003') {
            this.$cookies.remove('_business_card_user_token')
            this.$router.push({ path: '/Login' })
          } else {
            this.$cookies.remove('_business_card_user_token')
          }
        })
        .catch(res => {
          console.log(res)
          Toast('网络错误')
        })
    }
  }
}
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
</style>

<style>
  /* 小于400 */
  @media screen and (max-width: 300px) {
    html,
    body {
      font-size: 5vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 301px) and (max-width: 350px) {
    html,
    body {
      font-size: 4vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 351px) and (max-width: 420px) {
    html,
    body {
      font-size: 4vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 421px) and (max-width: 490px) {
    html,
    body {
      font-size: 4vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 491px) and (max-width: 620px) {
    html,
    body {
      font-size: 4vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 621px) and (max-width: 720px) {
    html,
    body {
      font-size: 3vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 721px) and (max-width: 820px) {
    html,
    body {
      font-size: 3vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 821px) and (max-width: 920px) {
    html,
    body {
      font-size: 3vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 921px) and (max-width: 1020px) {
    html,
    body {
      font-size: 2vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 1021px) and (max-width: 1120px) {
    html,
    body {
      font-size: 2vmin;
    }
  } /* 大于960 小于1200 */
  @media screen and (min-width: 1121px) and (max-width: 1220px) {
    html,
    body {
      font-size: 2vmin;
    }
  }
</style>
