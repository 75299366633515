const modulesFiles = require.context('./', true, /\.js$/)
// you do not need `import app from './modules/app'`

// it will auto require all vuex module from modules file

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  // 文件路径
  // console.log("modules, modulePath",module,modulePath)
  const moduleName = modulePath.replace(/(.*\/)*([^.]+).*/ig, '$2')
  const value = modulesFiles(modulePath)

  if (value === 'Module') {
    return
  }
  modules[moduleName] = value.default

  return modules
}, {})
// console.log("Common,:",modules)
export default modules
