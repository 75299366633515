<template>
  <div>
    <div id="card" class="card" :style="cardStyle">
      <div class="content" v-show="!isShow">

        <slot name="textTitle"></slot>

        <div class="info">

          <slot name="infoRows"></slot>

        </div>
      </div>

      <slot name="background"></slot>

      <div class="btn">
        <van-icon v-show="!isShow" name="eye-o" @click="isShow=true" />
        <van-icon v-show="isShow" name="closed-eye" @click="isShow=false" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nameCard',
  props: {
    attr: {
      type: Object,
      default () {
        return {}
      }
    },
    editStatus: {
      type: String,
      default () {
        return 'false'
      }
    }
  },
  data () {
    return {
      isShow: false,
      cardStyle: {
        backgroundImage: 'url(' + this.attr.bgImage.value + ')',
        backgroundSize: 'cover'
      },
      btnStyle: {
        color: this.attr.textColor.value
      },
      infoStyle: {
        color: this.attr.textColor.value
      }
    }
  },
  watch: {
    attr: {
      deep: true,
      handler (newValue, oldValue) {
        this.update()
      }

    }
  },
  created () {
    this.$nextTick(() => {
      this.update()
    })
    // console.log(this.attr)
  },
  methods: {
    onChange () {
    },
    update () {
      this.cardStyle.backgroundImage = 'url(' + this.attr.bgImage.value + ')'
      this.cardStyle.backgroundSize = 'cover'
      this.btnStyle.color = this.attr.textColor.value
      this.infoStyle.color = this.attr.textColor.value
      //  dom.style.color = this.attr.textColor.value
      this.getById((dom) => {
        dom.style.color = this.attr.textColor.value
      })
    },
    getById (fn) {
      for (const key in this.attr) {
        if (Object.hasOwnProperty.call(this.attr, key)) {
          const element = this.attr[key]
          const dom = document.getElementById(element.id)
          if (dom) {
            fn(dom)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }
  .card {
    width: 22.5rem;
    height: 13.75rem;
    margin: 15px auto;
    background: #000000;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
  }
  .content {
    padding: 1.875rem;
    position: relative;
    z-index: 100;
  }
  .text {
    cursor: move;
    line-height: 1.3;
    letter-spacing: 0px;

    color: rgb(255, 255, 255);
    word-break: break-all;
    writing-mode: horizontal-tb;
    opacity: 1;
  }
  .content .name {
    font-size: 2.2rem;
    font-weight: bold;
  }
  .content .occupation {
    font-size: 0.875rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .info {
    padding-top: 1.25rem;
    color: aliceblue;
  }
  .info .row {
    margin: 0.375rem 0;
  }
  .info .text {
    padding-left: 0.625rem;
  }
  .info .icon {
    position: relative;
    top: 0.125rem;
  }

  .btn{
    position: absolute;
    right: .625rem;
    bottom: .5rem;
    z-index: 181;
    color: aliceblue;
  }
</style>
