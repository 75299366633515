<script>
// import {  CubeTextureLoader } from 'three'
import * as THREE from 'three'
import Base from '../components/Base'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

export default {
  name: 'TextureScene',
  mixins: [Base],
  inject: ['scene'],
  props: {
    url: {
      type: [String, Array]
    },
    baseUrl: String,
    options: { type: Object, default: () => ({}) },
    bgColor: {
      type: String
    },
    envMap: String // 模型环境贴图
  },
  mounted () {
    if (this.envMap) {
      const textureLoader = new THREE.TextureLoader()
      const envMap = textureLoader.load(this.envMap)
      envMap.outputEncoding = THREE.sRGBEncoding
      this.scene.environment = envMap
    }
    if (this.url) {
      if (Array.isArray(this.url)) {
        this.Cube()
      } else {
        this.BackgroundImage()
      }
    } else if (this.bgColor) {
      this.scene.background = new THREE.Color(this.bgColor)
    }
  },
  beforeDestroy () {
    this.scene.background = null
  },
  destroyed () {},
  methods: {
    /** 返回文件 后缀名 */
    getFileType (filePath, fileName) {
      var startIndex = filePath.lastIndexOf('.')
      if (startIndex !== -1) {
        var name = filePath
          .substring(startIndex + 1, filePath.length)
          .toLowerCase()
        return name === fileName
      } else {
        return false
      }
    },
    BackgroundImage () {
      const textureLoader = new THREE.TextureLoader()
      const texture = textureLoader.load(this.url)
      texture.outputEncoding = THREE.sRGBEncoding
      this.scene.background = texture
    },
    backgroundHdr () {
      const pmremGenerator = new THREE.PMREMGenerator(this.$global.renderer)
      pmremGenerator.compileEquirectangularShader()
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .setPath(this.baseUrl)
        .load(this.url, texture => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture
          this.scene.background = envMap
          this.scene.environment = envMap
          texture.dispose()
          pmremGenerator.dispose()
        })
    },
    // 六面图
    Cube () {
      const loader = new THREE.CubeTextureLoader()
      if (this.baseUrl) {
        loader.setPath(this.baseUrl)
      }
      const texture = loader.load(this.url, cubeTexture => {
        cubeTexture.encoding = THREE.sRGBEncoding
      })
      this.scene.background = texture
    }
  }
}
</script>
