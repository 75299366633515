<template>
  <div  class="Preview">
    <van-nav-bar
      title="我的名片详情"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <!-- <div class="box"> -->
      <component
        v-if="cardInfo.homePageTemplate"
        :is="cardInfo.homePageTemplate"
        :attr="JSON.parse(cardInfo.homePageData)"
        :editStatus="editStatus"
      ></component>
    <!-- </div> -->

    <component
      v-if="cardInfo.selfIntroTemplate"
      :is="cardInfo.selfIntroTemplate"
      :attr="JSON.parse(cardInfo.selfIntroData)"
      :editStatus="editStatus"
    ></component>
    <component
      v-if="cardInfo.companyInfoTemplate"
      :is="cardInfo.companyInfoTemplate"
      :attr="JSON.parse(cardInfo.companyInfoData)"
      :editStatus="editStatus"
    ></component>
    <component
      v-if="cardInfo.productInfoTemplate"
      :is="cardInfo.productInfoTemplate"
      :attr="JSON.parse(cardInfo.productInfoData)"
      :editStatus="editStatus"
    ></component>
    <div style="height:4.5rem;"></div>
    <van-popup v-model="show" get-container="body">
      <div class="share-box">
        <img ref="img" src="" v-show="!imgLoad" />
<!--        <van-loading color="rgb(255 255 255)" v-if="imgLoad"  text-color="rgb(255 255 255)" type="spinner" size="50" >图片制作中...</van-loading>-->
      </div>
    </van-popup>
    <div style="display:none">
      <img ref="Logo" src="../../assets/logo.png" alt="">
      <img ref="Qr" src="" alt="">
      <img ref="thumbnailImg" src="" alt="">
    </div>
    <van-goods-action >
      <van-goods-action-icon icon='edit' text="编辑" @click="onEdit"/>
      <van-goods-action-button  type="default" text="分享名片" @click="onShare"/>
    </van-goods-action>
  </div>
</template>

<script>
// 用户自己的卡片预览
import axios from 'axios'
import { Toast } from 'vant'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import wxShare from '../../utils/wxShare'

export default {
  name: 'UserPreviewCard',
  data () {
    return {
      cardId: null,
      cardInfo: {},
      editStatus: 'false',
      show: false,
      imgLoad: true
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    onClickRight () {
      const req = {
        encryptCardId: this.cardId
      }

      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/card/share', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            console.log('share card', this.cardInfo)
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
      Toast.success('请在新页面使用浏览器菜单分享')
      this.$router.push({ path: '/PreviewCard/GivePreviewCard/' + encodeURIComponent(this.cardId) })
    },
    getCardInfo () {
      const req = {
        encryptCardId: this.cardId
      }

      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/card/get', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.cardInfo = res.data.cardInfo
            const imgUrl = location.protocol + '//' + location.hostname + ':' + location.port + '/' + this.cardInfo.thumbnail
            const href = location.protocol + '//' + location.hostname + ':' + location.port + '/#/PreviewCard/GivePreviewCard/' + encodeURIComponent(this.cardId)
            const config = {
              title: '来自神笔的3D名片',
              desc: '来自神笔的3D名片',
              link: href,
              imgUrl: imgUrl
            }
            wxShare.updateWxShareConfig(config)
          } else {
            Toast.fail('获取名片失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    },
    onEdit () {
      this.$store.commit(
        'setEditingCard',
        JSON.parse(JSON.stringify(this.cardInfo))
      )
      this.$router.push({ path: '/EditTemplate/EditCard/' + this.cardInfo.homePageTemplate })
    },
    onShare () {
      console.log('分享')
      const req = {
        encryptCardId: this.cardId
      }

      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/card/share', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            console.log('share card', this.cardInfo)
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
      this.show = true
      this.imgLoad = true
      this.canvasPic().then((val) => {
        this.imgLoad = false
      })
    },
    async canvasPic () {
      // const base64 = await this.screenshot()
      // const qr = await this.QRcode()
      this.$refs.thumbnailImg.src = await this.screenshot()
      this.$refs.Qr.src = await this.QRcode()
      const canvas = document.createElement('canvas')
      canvas.width = 600
      canvas.height = 580
      canvas.style.border = '1px solid #ccc'
      const ctx = canvas.getContext('2d')

      var thumbnailImg = this.$refs.thumbnailImg
      var QRimg = this.$refs.Qr
      var LogoImg = this.$refs.Logo
      // document.body.appendChild(LogoImg)
      // 第二步，图片加载完成后，把图片绘制到canvas上
      const Margin = 30

      var _this = this
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, 600, 580)
      ctx.fill()
      thumbnailImg.onload = await function () {
        ctx.drawImage(thumbnailImg, Margin, Margin, 540, 540 * 0.6)
      }
      LogoImg.onload = await function () {
        ctx.drawImage(LogoImg, 30, 540 * 0.6 + 60, 150, 150)
      }
      QRimg.onload = await function () {
        _this.$refs.img.src = canvas.toDataURL()
      }
      ctx.drawImage(thumbnailImg, Margin, Margin, 540, 540 * 0.6)
      ctx.drawImage(LogoImg, 30, 540 * 0.6 + 60, 150, 150)
      ctx.drawImage(QRimg, 600 - 30 - 150, 540 * 0.6 + 60, 150, 150)
      ctx.font = '12px "宋体"'
      ctx.fillStyle = '#000'
      ctx.textBaseline = 'top'
      ctx.fillText('神笔软件，创意展示，欢迎您的使用！(￣︶￣)', 33, 540 * 0.6 + 60 + 150 + 10)
      this.$refs.img.src = canvas.toDataURL()
      return 'load over 结束'
    },
    // 截图
    screenshot () {
      window.scrollTo(0, 0)
      const card = document.getElementById('card')
      return html2canvas(card).then(function (canvas) {
        // const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
        return canvas.toDataURL('image/png')
      })
    },
    // 二维码
    QRcode () {
      const QRCodeMsg = location.protocol + '//' + location.hostname + ':' + location.port + '/#/PreviewCard/GivePreviewCard/' + encodeURIComponent(this.cardId)
      console.log('Q', QRCodeMsg)
      const opts = {
        errorCorrectionLevel: 'H', // 容错级别
        type: 'image/png', // 生成的二维码类型
        quality: 0.5, // 二维码质量
        margin: 1, // 二维码留白边距
        width: 280, // 宽
        height: 280, // 高
        text: QRCodeMsg, // 二维码内容
        color: {
          dark: '#333333', // 前景色
          light: '#fff' // 背景色
        }
      }
      const msg = document.createElement('canvas')
      // 将获取到的数据（val）画到msg（canvas）上
      return new Promise((resolve, reject) => {
        QRCode.toCanvas(msg, QRCodeMsg, opts, function (error, canvas) {
          console.log(error)
          resolve(canvas.toDataURL())
        })
      })
    }
  },
  created () {
    this.cardId = this.$route.params.id
    console.log('cardId', this.cardId)
    const req = {
      encryptCardId: this.cardId
    }

    const fd = new FormData()
    console.log('req', req)
    fd.append('req', JSON.stringify(req))
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    axios
      .post('/card/check', fd, config)
      .then(res => {
        console.log(res)
        if (res.data.returnCode === '0') {
          this.getCardInfo()
        } else if (res.data.returnCode === '003') {
          this.$router.push({ path: '/PreviewCard/GivePreviewCard/' + encodeURIComponent(this.cardId) })
        } else {
          Toast.fail('检查名片用户失败')
        }
      })
      .catch(res => {
        console.log(res)
        Toast.fail('网络错误')
      })
  },
  components: {}
}
</script>
<style scoped>
.attr {
  width: 100%;
  position: fixed;
  bottom: 2.5rem;
  left: 0;
  text-align: center;
}
.Preview {
  width: 100vw;
  min-height: 100vh;
  background-color: rgb(244 244 244);
}
.share-box{
  text-align: center;
}
.share-box >>> img{
  width: 95vmin;
}
/* .box {
  margin-top: 15px;
} */
.van-goods-action-icon {

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 48px;
  height: 100%;
  color: #646566;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  background-color: #f4f4f4;
  cursor: pointer;
}
</style>
