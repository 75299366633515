<template>
  <div class="scene">

  <renderer :size="size" :insetsize="insetsize" :alpha="true">
    <transition name="slide-fade">
      <div class="LoadAnimation" v-show="loade">
        <LoadAnimation2 :percentage="percentage"></LoadAnimation2>
      </div>
    </transition>
    <scene :gridHelper="false">
      <orbit-controls
        :minDistance="minDistance"
        :maxDistance="maxDistance"
        :target="target"
        :autoRotate="true"
        :autoRotateSpeed="autoRotateSpeed"
        :angle="angle"
        ref="control"
      >
        <camera
          ref="camera"
          :position="CameraPosition"
          :rotation="CameraRotation"
        ></camera>
      </orbit-controls>
      <audio-listener :position="{ x: 0, y: 0, z: 0 }"></audio-listener>
      <DirectionLight
        :position="{ x: 100, y: 100, z: 100 }"
        hex="#fff"
        :intensity="1.2"
      ></DirectionLight>
      <DirectionLight
        :position="{ x: -100, y: 100, z: 100 }"
        hex="#fff"
        :intensity="0.2"
      ></DirectionLight>
      <DirectionLight
        :position="{ x: 0, y: 100, z: -100 }"
        hex="#fff"
        :intensity="0.2"
      ></DirectionLight>
      <model
        @getModels="getObj"
        @progress="progressBar"
        :modelSrc="modelSrc"
        @loadingOver="loadingOver"
        @loadErro="loadErro"
      ></model>
      <!-- <TextureScene bgColor="red"></TextureScene> -->
      <ambient-light loadErro hex="#fff" :intensity="0.1"></ambient-light>
      <raycaster></raycaster>
    </scene>
  </renderer>

  </div>
</template>

<script>
import * as THREE from 'three'
import { Toast } from 'vant'
export default {
  name: 'background',
  data () {
    return {
      target: { x: 0, y: 0, z: 0 },
      maxDistance: 500,
      percentage: 0,
      loade: true
    }
  },
  props: {
    modelSrc: {
      type: String,
      default () {
        return ''
      }
    },
    size: {
      // 画布大小
      type: Object,
      default () {
        return { w: 0, h: 0 }
      }
    },
    insetsize: {
      // 小地图大小
      type: Object,
      default () {
        return { w: 0, h: 0 }
      }
    },
    CameraPosition: {
      // 相机位置
      type: Object,
      default () {
        return { x: 0, y: 500, z: 0 }
      }
    },
    CameraRotation: {
      // 相机角度
      type: Object,
      default () {
        return { x: 0, y: 0, z: 0 }
      }
    },
    minDistance: {
      type: null,
      default () {
        return 0
      }
    },
    maxDistanceProps: {
      type: null,
      default () {
        return 500
      }
    },
    // 自动旋转
    autoRotate: {
      type: Boolean,
      default () {
        return true
      }
    },
    // 围绕物体旋转速度
    autoRotateSpeed: {
      type: null,
      default () {
        return 1
      }
    },
    // 角度
    angle: {
      type: Number,
      default () {
        return 2
      }
    },
    distance: {
      type: Number,
      default () {
        return 1
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    getObj (obj) {
      if (this.$refs.camera.curObj) {
        const camera = this.$refs.camera.curObj
        this.set_Model_centered(obj, camera)
      }
    },
    progressBar (val) {
      console.log('progressBar', val)
      val = Number(val)
      this.percentage = val.toFixed(0)
    },
    loadingOver () {
      if (this.percentage >= 100) {
        this.loade = false
        this.$bus.$emit('loadingOver')
        return
      }
      const time = setInterval(() => {
        this.percentage += 1
        if (this.percentage >= 100) {
          clearTimeout(time)
          this.$bus.$emit('loadingOver')
          this.loade = false
        }
      }, 10)
    },
    loadErro () {
      Toast.fail('模型损坏或加载失败')
      this.$bus.$emit('loadingOver')
      this.loade = false
    },
    set_Model_centered (object, camera) {
      // // object.updateMatrixWorld(true)
      // // object.geometry.center()
      // // object.geometry.computeBoundingBox()
      // const box = new THREE.Box3().setFromObject(object)
      // console.log('sdaffa', box)
      // // const mdlen = box.max.x - box.min.x // 模型长度
      // // const mdwid = box.max.z - box.min.z // 模型宽度
      // // const mdhei = box.max.y - box.min.y // 模型高度
      // // const x1 = box.min.x + mdlen / 2 // 模型中心点坐标X
      // // const y1 = box.min.y + mdhei / 2 // 模型中心点坐标Y
      // // const z1 = box.min.z + mdwid / 2 // 模型中心点坐标Z
      // // object.position.set(-x1, -y1 / 2, -z1) // 将模型进行偏移
      // const boxSize = box.getSize(new THREE.Vector3())
      // const center = box.getCenter(new THREE.Vector3())
      // object.position.x += object.position.x - center.x
      // object.position.y += object.position.y - center.y // 修改center.y可以设置模型整体上下偏移
      // object.position.z += object.position.z - center.z

      // camera.position.copy(center)
      // if (boxSize.x > boxSize.y) {
      //   camera.position.z = boxSize.x * -2.85
      // } else {
      //   camera.position.z = boxSize.y * -2.85
      // }
      // console.log('dddddd', center)
      const box = new THREE.Box3().setFromObject(object)
      const size = box.getSize(new THREE.Vector3()).length()
      const center = box.getCenter(new THREE.Vector3())

      object.position.x = object.position.x - center.x
      object.position.y = object.position.y - center.y
      object.position.z = object.position.z - center.z

      this.maxDistance = size * 10
      // this.minDistance = size
      camera.updateProjectionMatrix()

      camera.position.copy(center)
      camera.position.x = size * this.distance
      camera.position.y = size * this.distance
      camera.position.z = size * this.distance

      // let scale = this.getFitScaleValue(object, camera)
      // if (scale < 0) {
      //   scale = -scale
      // }
      // object.scale.set(scale, scale, scale)
      // console.log('size', size)
      // console.log('scale', scale)
      // console.log('center', center)
      // console.log('centobject.position', object.position)
      // console.log('camera.position', camera.position)
      this.target = { x: 0, y: 0, z: 0 }
      return object
    },
    // 获取模型适合观察的缩放的比例
    getFitScaleValue (obj, camera) {
      var boxHelper = new THREE.BoxHelper(obj)
      boxHelper.geometry.computeBoundingBox()
      var box = boxHelper.geometry.boundingBox
      var maxDiameter = Math.max(
        box.max.x - box.min.x,
        box.max.y - box.min.y,
        box.max.z - box.min.z
      )
      return camera.position.z / maxDiameter
    }
  },
  beforeDestroy () {}
}
</script>

<style scoped>
.LoadAnimation {
  position: relative;
  top: 0;
  left: 0;
  z-index: 67;
  width: 100%;
  height: 100%;
}

.scene{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
</style>
