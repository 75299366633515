<script>
export default {
  name: 'card',
  props: {
    attr: {
      type: Object,
      default () {
        return {}
      }
    },
    editStatus: {
      type: String,
      default () {
        return 'false'
      }
    }
  }
}
</script>

<style>

</style>
