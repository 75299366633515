<template>
  <div ref="cssDiv" class="cssDiv"></div>
</template>

<script>
import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import Object3D from './../components/Object3D'
export default {
  name: 'cssObject',
  mixins: [Object3D],
  props: {
    size: {
      type: Object, // { w, h }
      // required: true,
      default () {
        return { w: 1920, h: 1080 }
      }
    },
    url: {
      type: String, // { w, h }
      // required: true,
      default () {
        return ''
      }
    }
  },
  data () {
    const curObj = new THREE.Group()
    return { curObj }
  },
  mounted () {
    const dom = this.$refs.cssDiv
    dom.style.width = `${this.size.w}px`
    dom.style.height = `${this.size.h}px`
    const iframe = document.createElement('iframe')
    iframe.style.width = '100%'
    iframe.style.height = '100%'
    iframe.style.border = '0px'
    console.log('DSAF', this.url)
    iframe.src = [this.url]
    dom.appendChild(iframe)
    const object = new CSS2DObject(dom)
    this.curObj.add(object)
  }
}
</script>

<style scoped>
.cssDiv {
  background-color: rgb(174, 227, 252);
}
</style>
