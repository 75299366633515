import PreviewReceivedCard from '../views/Preview/PreviewReceivedCard.vue'
import UserPreviewCard from '../views/Preview/UserPreviewCard.vue'
import GivePreviewCard from '../views/Preview/GivePreviewCard.vue'
import PreviewCard from '../views/Preview/PreviewCard.vue'

const Preview = {
  path: '/PreviewCard',
  require: '/PreviewCard/CardTemplateList',
  component: PreviewCard,
  children: [
    {
      path: 'PreviewReceivedCard/:id',
      name: 'PreviewReceivedCard',
      meta: {
        title: 'Login',
        requireAuth: true
      },
      component: PreviewReceivedCard
    },
    {
      path: 'UserPreviewCard/:id',
      name: 'UserPreviewCard',
      meta: {
        title: 'Login',
        requireAuth: true
      },
      component: UserPreviewCard
    },
    { // 接受预览
      path: 'GivePreviewCard/:id',
      name: 'GivePreviewCard',
      props: true,
      component: GivePreviewCard
    }
  ]
}

export default Preview
