<script>
import * as THREE from 'three'

export default {
  name: 'fontLoader',
  inject: ['meshVm'],
  props: {
    text: String
  },
  data () {
    return {
      textLoader: {}
    }
  },
  mounted () {
    this.$bus.$on('loaderText', val => {
      const textGeo = new THREE.TextGeometry(this.text, val)
      textGeo.center()
      textGeo.computeBoundingBox()
      this.meshVm.curObj.geometry = textGeo
    })
  },
  methods: {},
  beforeDestroy () {
    this.$bus.$off('loaderText')
  }
}
</script>

<style></style>
