<script>
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
export default {
  name: 'canvasTitle',
  inject: ['scene'],
  props: {
    title: String,
    position: {
      type: Object,
      default () {
        return { x: 0, y: 0, z: 0 }
      }
    },
    scale: {
      type: Number,
      default () {
        return 0.5
      }
    },
    src: String,
    size: Number
  },
  data () {
    return {}
  },
  mounted () {
    const element = document.createElement('div')
    element.textContent = this.title
    if (this.src) {
      const _this = this
      element.onclick = function () {
        window.open(_this.src)
      }
    }
    if (this.size) {
      element.style.fontSize = this.size + 'px'
    }

    element.style.cursor = 'pointer'
    element.className = 'canvas-title'
    const objectCSS = new CSS2DObject(element)
    objectCSS.position.x = this.position.x
    objectCSS.position.y = this.position.y
    objectCSS.position.z = this.position.z
    objectCSS.scale.set(this.scale, this.scale, 1)
    this.scene.add(objectCSS)
  }
}
</script>

<style>
.canvas-title {
  font-size: 50px;
  color: lightskyblue;
}
</style>
