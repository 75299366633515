<template>
  <div>
    <van-nav-bar
      title="我的名片"
      :left-arrow="false"
      right-text="创建名片"
      @click-right="onClickRight"
      :fixed="true" :placeholder="true"
    />
    <div class="box">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad">
      <van-grid :column-num="1">
        <van-grid-item v-for="(item, i) in list" :key="i">
          <van-swipe-cell>
            <van-cell :border="false" @click="OpenUserCard(item, i)">
              <div class="card-img">
                <div class="img-box">
                  <van-image :src="item.thumbnail">
<!--                    <template v-slot:loading>-->
<!--                      <div class="loading">-->
<!--                        <div class="position">-->
<!--                          <p>-->
<!--                            <van-loading-->
<!--                              color="rgb(25 137 250)"-->
<!--                              text-color="rgb(25 137 250)"-->
<!--                              type="spinner"-->
<!--                              size="50"-->
<!--                              >名片加载中...</van-loading-->
<!--                            >-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </template>-->
                    <template v-slot:error>
                      <div class="error">
                        <van-empty
                          image="network"
                          description="名片消失了/(ㄒoㄒ)/~~"
                        />
                      </div>
                    </template>
                  </van-image>
                </div>
                <div class="card-info">
                  <div class="item">
                    <div class="icon">
                      <van-icon name="eye-o" />
                    </div>
                    <div class="text">
                      {{ item.visitTimes }}
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon">
                      <van-icon name="share-o" />
                    </div>
                    <div class="text">
                      {{ item.shareTimes }}
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon">
                      <van-icon name="star-o" />
                    </div>
                    <div class="text">
                      {{ item.collectedTimes }}
                    </div>
                  </div>
                </div>
              </div>
            </van-cell>
            <template #right>
              <van-button
                square
                class="btn"
                icon="delete-o"
                round
                color="#646566"
                plain
                type="info"
                text="删除"
                @click="DeleteCard(item)"
              />
            </template>
          </van-swipe-cell>
        </van-grid-item>
      </van-grid>
      </van-list>
      <van-empty v-if="list.length === 0"
                 class="custom-image"
                 image="/img/resource/no_content.png"
                 description="还有没有名片？赶紧做一个吧"
      >
        <div class="info-btn">
          <van-button type="info" round block plain @click="onClickRight">做一个名片吧</van-button>
        </div>
      </van-empty>
    </div>
  </div>
</template>

<script>
import tempList from '../../components/TemplateJS'
import axios from 'axios'
import { Toast } from 'vant'
export default {
  name: 'UserBusinessCard',
  data () {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 5,
      loading: false,
      finished: false
    }
  },
  created () {
    delete tempList.index
    this.list = tempList
  },
  mounted () {
    this.pageNumber = 1
    this.list = []
  },
  methods: {
    onClickRight () {
      this.$router.push({ path: '/EditTemplate' })
    },
    onLoad () {
      this.getList()
    },
    OpenUserCard (item) {
      this.$router.push({
        path:
          '/PreviewCard/UserPreviewCard/' + encodeURIComponent(item.encryptId)
      })
    },
    DeleteCard (item, i) {
      const req = {
        encryptCardId: item.encryptId
      }

      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/card/delete', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.list.splice(i, 1)
            console.log('this.list', this.list)
            // Toast.success('删除成功')
          } else if (res.data.returnCode === '003') {
            Toast.fail('用户状态错误')
            this.$router.push({ path: '/login' })
          } else if (res.data.returnCode === '001') {
            Toast.fail('参数错误')
          } else {
            Toast.fail('删除失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    },
    EditCard (item) {
      console.log('item', item)
    },
    getList () {
      const req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }

      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/card/getList', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.loading = false
            if (res.data.cardInfoList.length < 5) {
              this.finished = true
            } else {
              this.pageNumber += 1
            }
            if (res.data.cardInfoList.length > 0) {
              this.list = this.list.concat(res.data.cardInfoList)
            }

            console.log('this.list', this.list)
          } else if (res.data.returnCode === '003') {
            this.$router.push({ path: '/login' })
            Toast.fail('用户状态错误')
          } else if (res.data.returnCode === '001') {
            Toast.fail('用户信息错误')
          } else {
            Toast.fail('获取列表失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
* >>> .btn {
  padding: 0 0.9375rem;
  height: 100%;
  display: block;
  border: none;
  outline: none;
  background: none;
  color: #111;
}
.box >>> .van-cell {
  padding: 4px 0;
  line-height: 0px;
}
.box >>> .van-grid-item__content {
  padding: 5px;
}
.box >>> .van-grid-item__content:first-child {
  padding: 10px 5px 5px 5px;
}
.card-share {
  position: absolute;
  right: 0.625rem;
  bottom: 0.5rem;
  font-size: 1.875rem;
  background: #111;
}
.card-img {
  width: 22.5rem;
    height: inherit;
  overflow: hidden;
  text-align: center;
}
.img-box{
  width: 100%;
  height: 13.75rem;
  position: relative;
}
.card-img >>> .van-image {
  width: 100%;
  height: 100%;
}
.card-info {
  display: flex;
  padding: 1px 0.1875rem;
  line-height: 12px;
}
.card-info .item {
  display: flex;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #c7c7c7;
}
.item .icon {
  position: relative;
  top: 0px;
}
.item .text {
  padding: 0 0.5rem;
}
.error {
  width: 22.5rem;
  position: relative;
  text-align: center;
}
.info-btn{
  padding: 1rem 15px;
  width: 60vw;
}
.loading {
  width: 22.5rem;
  position: relative;
  text-align: center;
  margin: 0 auto;
  background-color: #f7f8fa;
}
.loading .position {
  position: absolute;
  right: 0;
  bottom: 45%;
  z-index: 333;
  width: 100%;
}
.loading .position p {
  display: inline-block;
}

.custom-image >>> .van-empty__image {
  width: 16rem;
  height: 14rem;
}
</style>
