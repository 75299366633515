<template>
  <div :class="rowClass">
    <span v-if="iconShow" :class="iconClass">
      <van-icon :name="iconName"/>
    </span>
    <span :class="textClass"
          :id="rowId"
          :contenteditable="editStatus">
      {{textValue}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'textRow',
  props: {
    rowId: {
      type: String,
      default () {
        return ''
      }
    },
    rowClass: {
      type: Object,
      default () {
        return {
          row: true
        }
      }
    },
    iconShow: {
      type: Boolean,
      default () {
        return true
      }
    },
    iconClass: {
      type: Array,
      default () {
        return ['icon']
      }
    },
    iconName: {
      type: String,
      default () {
        return 'star-o'
      }
    },
    textClass: {
      type: Array,
      default () {
        return ['text']
      }
    },
    textValue: {
      type: String,
      default () {
        return ''
      }
    },
    editStatus: {
      type: String,
      default () {
        return 'false'
      }
    }
  }
}
</script>

<style scoped>
  .row {
    margin: 0.375rem 0;
  }
  .text {
    padding-left: 0.625rem;
  }
  .icon {
    position: relative;
    top: 0.125rem;
  }
  .textTitle {
    cursor: move;
    line-height: 1.3;
    letter-spacing: 0px;

    color: rgb(255, 255, 255);
    word-break: break-all;
    writing-mode: horizontal-tb;
    opacity: 1;
  }
  .name {
    font-size: 2.2rem;
    font-weight: bold;
  }
  .occupation {
    font-size: 0.875rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
</style>
