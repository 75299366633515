<template>
  <div>
    <van-nav-bar
      title="名片信息"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true" :placeholder="true"
    />
    <Preview :name="name" :attr="attr" editStatus="true" ></Preview>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div style="height:1.2rem"></div>
      <div class="row">
        <div v-for="(val, key, i) in attr" :key="i" class="">
          <div v-if="val.type === 'color'" class="item">
            <div class="color">
              <el-color-picker v-model="val.value" show-alpha></el-color-picker>
            </div>
            <div class="color-text">{{ val.label }}</div>
          </div>
          <div v-if="val.type === 'model'" class="item">
             <div class="model">
              <van-uploader :max-size="10000 * 1024" :after-read="afterRead"  @oversize="onOversizeModel" :name="key" :before-read="beforeRead">
                <div>
                  <div class="model-icon">
                    <van-image src="icon/model.png">
                      <template v-slot:error>加载失败</template>
                    </van-image>
                  </div>
                  <div class="model-text">模型上传</div>
                </div>
              </van-uploader>
            </div>
          </div>
          <div v-if="val.type === 'image'" class="item">
            <div class="image">
               <van-uploader :max-size="5000 * 1024"  :after-read="afterRead"  @oversize="onOversizeImage" :name="key">
             <div>
               <div class="image-icon">
                    <van-image src="icon/pic.png">
                      <template v-slot:error>加载失败</template>
                    </van-image>
                  </div>
                  <div class="image-text">{{val.label}}</div>
             </div>
            </van-uploader>
            </div>

          </div>
        </div>
      </div>
    </van-popup>
    <div class="btn-left">
      <van-button type="info" @click="SaveAttr" :disabled="disableBut">
        保存
      </van-button>
    </div>
    <div class="btn-right">
      <van-button type="info" @click="onMenu" :disabled="disableBut">
        菜单
      </van-button>
    </div>
    <van-overlay z-index="999" :show="cardUploading" >
      <div class="wrapper ">
      <van-loading type="spinner" size="4rem" color="#1989fa" vertical>您的3D名片正在生成中，请稍后</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import template from '../../components/TemplateJS'
import Preview from '../../components/Preview'
import html2canvas from 'html2canvas'
import axios from 'axios'
import { Toast } from 'vant'

export default {
  name: 'EditCard',
  data () {
    return {
      attr: null,
      uploadData: { },
      name: '',
      category: '',
      thumbnail: '',
      show: false,
      saveData: {},
      cardUploading: false,
      disableBut: true
    }
  },
  mounted () {
    console.log(this.$bus)
    this.$bus.$once('loadingOver', () => {
      this.disableBut = false
    })
    delete template.index
    console.log('template', template)
    this.name = this.$route.params.name
    console.log('name', this.name)
    this.QueryConfiguration()
    // this.attr = template.cardTemplateVip01.attr
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
      // this.$router.push({ path: '/EditTemplate' })
    },
    onMenu () {
      this.show = true
    },
    onOversizeModel () {
      Toast.fail('上传模型不能大于10M')
    },
    onOversizeImage () {
      Toast.fail('上传图片不能大于5M')
    },
    // 查找对应的模板配置数据
    QueryConfiguration () {
      var UserInfo = JSON.parse(JSON.stringify(this.$store.state.UserInfo))
      for (const key in template) {
        if (Object.hasOwnProperty.call(template, key)) {
          console.log('key', key)
          if (key === this.name) {
            const element = template[key].attr
            this.attr = JSON.parse(JSON.stringify(element))
            this.category = template[key].category
            console.log(this.attr)
            break
          }
        }
      }
      for (const key in UserInfo) {
        if (Object.hasOwnProperty.call(UserInfo, key)) {
          const element = UserInfo[key]
          if (element) {
            this.attr[key].value = element
          }
        }
      }
    },

    // 保存信息
    SaveAttr () {
      this.cardUploading = true
      this.saveData = JSON.parse(JSON.stringify(this.attr))
      for (const key in this.saveData) {
        if (Object.hasOwnProperty.call(this.attr, key)) {
          const element = this.saveData[key]
          const dom = document.getElementById(element.id)
          if (dom) {
            element.value = dom.textContent
          }
        }
      }
      const request = {}
      return this.saveCard(request).then(() => {
        this.uploadModels()
        Toast.success('添加成功')
      })
      // this.$router.push({
      //   // path: '/EditTemplate/EditPreviewCard/' + this.name + '/' + uuid
      // })
    },
    saveCard (request) {
      const req = this.$store.state.editingCard
      Object.assign(req, request)
      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      return axios
        .post('/card/add', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.$store.commit(
              'setEditingCard',
              JSON.parse(JSON.stringify(res.data.cardInfo))
            )
          } else if (res.data.returnCode === '003') {
            Toast.fail('用户状态错误')
            this.$router.push({ path: '/Login' })
          } else if (res.data.returnCode === '001') {
            Toast.fail('用户信息错误')
          } else {
            Toast.fail('保存失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
      // this.$router.push({
      //   // path: '/EditTemplate/EditPreviewCard/' + this.name + '/' + uuid
      // })
    },
    beforeRead (file) {
      const postfix = file.name.substring(file.name.lastIndexOf('.'))
      if (postfix !== '.gltf' && postfix !== '.glb') {
        Toast('请上传 gltf 格式模型')
        return false
      }
      return true
    },
    // 切换模型
    afterRead (file, detail) {
      console.log('file', file)
      console.log('detail', detail)
      this.attr[detail.name].value = file.content
      this.uploadData[detail.name] = {
        file: file,
        detail: detail
      }
    },
    uploadModels () {
      const promiseArray = []
      if (this.category === 'card') {
        promiseArray.push(this.screenshot())
      }
      for (const key in this.uploadData) {
        console.log('uploadData.' + key, this.uploadData[key])
        promiseArray.push(this.uploadModel(this.uploadData[key].file, this.uploadData[key].detail))
      }

      Promise.all(promiseArray).then(() => {
        console.log('this.saveData ', this.saveData)
        const request = {}
        if (this.category === 'card') {
          request.homePageTemplate = this.name
          request.homePageData = JSON.stringify(this.saveData)
          request.thumbnail = this.thumbnail
        }
        if (this.category === 'product') {
          request.productInfoTemplate = this.name
          request.productInfoData = JSON.stringify(this.saveData)
        }
        this.saveCard(request).then(() => {
          this.cardUploading = false
          this.$router.back()
        })
      })
    },
    uploadModel (file, detail) {
      const card = this.$store.state.editingCard
      const postfix = file.file.name.substring(file.file.name.lastIndexOf('.'))
      const req = {
        cardId: card.id,
        encryptCardId: card.encryptId,
        fileType: 2,
        fileName: this.name + '.' + detail.name + postfix
      }
      console.log('req', req)

      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('req', JSON.stringify(req))
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      return axios.post('/file/upload/', formData, config)
        .then(response => {
          console.log('response', response)
          Toast.success('元素上传成功')
          this.saveData[detail.name].value = '/api' + response.data.fileUrl
        })
    },
    // 截图
    screenshot () {
      window.scrollTo(0, 0)
      const card = document.getElementById('card')
      console.log(card)
      const _this = this
      // const dom = document.createElement('canvas')
      // var gl = dom.getContext('webgl') canvas: gl.canvas,
      return html2canvas(card).then((canvas) => {
        const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
        const card = _this.$store.state.editingCard
        const fileName = 'thumbnail' + card.id + '.jpg'
        const req = {
          cardId: card.id,
          encryptCardId: card.encryptId,
          fileType: 0,
          fileName: fileName
        }
        console.log('req', req)
        const blob = _this.dataURLtoFile(url, fileName)// base64转文件
        const formData = new FormData()
        formData.append('file', blob)
        formData.append('req', JSON.stringify(req))
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
        return axios.post('/file/upload/', formData, config)
          .then(response => {
            console.log('response', response)
            this.thumbnail = '/api' + response.data.fileUrl
          })
      })
    },
    // base64 转图片文件
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename + '.jpeg', { type: 'image/jpeg' })
    },
    loadingOver () {
      console.log('测试测试------')
    }
  },
  beforeDestroy () {
    this.attr = null
  },
  components: {
    Preview
  }
}
</script>

<style scoped>
.row {
  /* display: grid;
  grid-template-columns: 100%;
  grid-template-rows:auto; */
  display: flex;
  padding: 0 5vmin;
  flex-wrap: wrap;
}
.item {
  min-width: 30vmin;
  height: 7.5rem;
  text-align: center;
}
.model{

}
.model .model-icon{
  padding: 0.5rem 1rem;
  width: 4rem;
  height: 4rem;
}

.image{

}
.image .image-icon{
  padding: 0.5rem 1rem;
  width: 4rem;
  height: 4rem;
}
.color {
  display: inline-block;
  padding: 0.5rem 1rem;
}
.color >>> .el-color-picker__trigger{
  width: 4rem ;
  height: 4rem;
}
.color-text {
  position: relative;
  top: -3px;
  font-size: 1rem;
}
.btn-left {
  position: absolute;
  z-index: 333;
  left: 0;
  bottom: 1rem;
}

.btn-right {
  position: absolute;
  z-index: 333;
  right: 0;
  bottom: 1rem;
}
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
