<template>
  <div>
    <van-nav-bar
      title="忘记密码"
      left-text="返回"
      left-arrow
      @click-left="ReturnPrevious"
    />
    <div style="width:100%;height:30px;"></div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        label="手机号"
        name="validator"
        placeholder="手机号"
        :rules="[{ validator, message: '请填写正确的手机号' }]"
      />
      <van-field
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
         <template #button>
          <van-button
            @click="SendVerification"
            :disabled="disabledSms"
            size="small"
            type="primary"
          >
            <div class="time">
              <div>发送验证码</div>
              <van-count-down
                v-show="disabledSms"
                ref="countDown"
                millisecond
                format="ss"
                :auto-start="false"
                @finish="finish"
                :time="time"
              ></van-count-down>
            </div>
          </van-button>
        </template>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="新密码"
        placeholder="密码"
        :rules="[{ validator:validatorPwd, message: '密码至少八位，至少一个字母和一个数字：' }]"
      />
      <van-field
        v-model="confirmPassword"
        type="password"
        name="确认密码"
        label="确认密码"
        placeholder="确认密码"
        @input="VerifyPassword"
        :error-message="errorMsg"
        :rules="[
          { required: true, message: '请填写密码' }
        ]"
      />
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
import Md5 from 'js-md5'
export default {
  name: 'ForgetPassword',
  data () {
    return {
      phone: '',
      sms: '',
      password: '',
      confirmPassword: '',
      errorMsg: '',
      time: 60000,
      disabledSms: false
    }
  },
  methods: {
    validator (val) {
      return /^1[0-9]{10}$/.test(val)
    },
    validatorPwd (val) {
      return /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(val)
    },
    ReturnPrevious () {
      this.$router.go(-1)
    },
    onSubmit () {
      const phone = this.phone
      const password = Md5(this.password)
      const vCode = this.sms
      const user = {
        mobileNo: phone,
        newPsw: password,
        verifyCode: vCode
      }
      console.log('this.user', user)
      var fd = new FormData()
      fd.append('req', JSON.stringify(user))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/user/forget-psw', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.$router.push({ path: '/Login' })
            Toast.success('修改密码成功成功')
          } else if (res.data.returnCode === '002') {
            Toast.fail('用户不存在')
          } else if (res.data.returnCode === '004') {
            Toast.fail('验证码错误')
          } else {
            Toast.fail('修改密码失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    },
    VerifyPassword () {
      if (this.password === this.confirmPassword) {
        this.errorMsg = ''
      } else {
        this.errorMsg = '两次密码不一致'
      }
    },
    SendVerification () {
      this.start()
      const fd = new FormData()
      fd.append('phoneNo', this.phone)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/sms/changePwdCode', fd, config)
        .then(res => {
          console.log(res)
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
      this.disabledSms = true
    },
    start () {
      this.$refs.countDown.start()
    },
    reset () {
      this.$refs.countDown.reset()
    },
    finish () {
      this.disabledSms = false
      this.reset()
    }
  }
}
</script>

<style scoped>
.time {
  display: flex;
}
.time >>> .van-count-down {
  color: aliceblue;
  font-size: 12px;
  line-height: 1;
}
</style>
