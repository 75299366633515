<template>
  <!-- 模板列表 -->
  <div class="main">
    <van-nav-bar title="模板列表" left-text="返回" left-arrow @click-left="onClickLeft" :fixed="true" :placeholder="true" />
    <div class="content">

        <van-tabs v-model="activeKey" @change="onChange" border >
          <van-tab title="个人名片" />
          <van-tab title="个人介绍" />
          <van-tab title="公司介绍" />
          <van-tab title="产品介绍" />
        </van-tabs>
        <van-grid :column-num="1">
          <div v-show="!show" class="existence">
            <van-grid-item
              v-for="value in currentList"
              :key="value.templateName"
            >
              <div class="box" @click="CreateCard(value.templateName)">
                <van-image :src="value.imgSrc">
<!--                  <template v-slot:loading>-->
<!--                      <div class="loading">-->
<!--                        <div class="position">-->
<!--                          <p><van-loading color="rgb(25 137 250)"  text-color="rgb(25 137 250)" type="spinner" size="50" >名片加载中...</van-loading></p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                  </template>-->
                  <template v-slot:error>
                    <div class="error">
                      <van-empty image="network" description="名片消失了/(ㄒoㄒ)/~~" />
                    </div>
                  </template>
                </van-image>
                <div class="text">
                  <span>{{value.desc}}</span>
                  <div v-show="value.vipGrade === 0" class="vip vip0">免费</div>
                  <div v-show="value.vipGrade === 1" class="vip vip1">VIP</div>
                  <div v-show="value.vipGrade === 2" class="vip vip2">SVIP专享</div>
                </div>
              </div>
            </van-grid-item>
          </div>
          <div v-show="show" class="empty">
            <div>
                <van-empty
                  class="custom-image"
                  image="/img/resource/working.gif"
                  description="日夜创作中，敬请期待..." />
              <div></div>
            </div>
          </div>
        </van-grid>

    </div>
  </div>
</template>

<script>
import TemplateList from '../../components/TemplateJS/index'
export default {
  components: {},
  name: 'CardTemplateList',
  data () {
    return {
      TemplateList,
      currentList: [],
      activeKey: 0,
      loading: false,
      finished: false
    }
  },
  created () {
    delete TemplateList.index
    this.onChange(0)
  },
  computed: {
    show () {
      return this.currentList.length === 0
    }
  },
  methods: {
    onClickLeft () {
      this.$store.commit('setUUID', '')
      this.$store.commit('setEditingCard', {})
      this.$router.go(-1)
    },
    // 创建名片
    CreateCard (templateName = '') {
      console.log('Template', templateName)
      this.$router.push({ path: '/EditTemplate/EditCard/' + templateName })
    },
    onChange (index) {
      this.currentList = []
      console.log(this.TemplateList, '++++')
      switch (index) {
        case 0:
          for (const key in this.TemplateList) {
            if (Object.hasOwnProperty.call(this.TemplateList, key)) {
              const element = this.TemplateList[key]
              if (element.category === 'card') {
                this.currentList.push(element)
              }
            }
          }

          break
        case 1:
          // company
          for (const key in this.TemplateList) {
            if (Object.hasOwnProperty.call(this.TemplateList, key)) {
              const element = this.TemplateList[key]
              if (element.category === 'company') {
                this.currentList.push(element)
              }
            }
          }
          break
        case 2:
          // personali_ntroduction
          for (const key in this.TemplateList) {
            if (Object.hasOwnProperty.call(this.TemplateList, key)) {
              const element = this.TemplateList[key]
              if (element.category === 'personali_ntroduction') {
                this.currentList.push(element)
              }
            }
          }
          break
        case 3:
          // product
          for (const key in this.TemplateList) {
            if (Object.hasOwnProperty.call(this.TemplateList, key)) {
              const element = this.TemplateList[key]
              if (element.category === 'product') {
                this.currentList.push(element)
              }
            }
          }
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped>

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
}
.left {
  flex-basis: auto;
  height: 100%;
}
.right {
  position: relative;
  flex-basis: auto;
  width: 100%;
  height: 100%;
}
.right >>> .van-grid-item {
  width: 100%;
  overflow: hidden;
}
.right >>> .van-image{
  min-height: 40vmin;
  width: 100%;
}
.right >>> .van-image__error{
  height: 52vmin;

  *zoom: 1;
}
.right >>> .van-grid-item__content{
  padding: 5px;
}
.existence{
  width: 100%;
  overflow: hidden;
}
.box{
  width: 100%;
  min-height:  55vmin;
  position: relative;
}
.empty {
  width: 100%;
  text-align: center;
}
.empty >>> .van-empty__description{
  padding: 0;
}

.empty .custom-image >>> .van-empty__image {
  width: 16rem;
  height: 12rem;
}

.text {
  color: rgb(0, 0, 0);
  font-size: 14px;

  text-align: left;
  border-radius: .1875rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.error{
  width: 100%;
  height:  52vmin;
  position: relative;
  text-align: center;
}

.loading {
  width: 100%;
  height:  100%;
  position: relative;
  text-align: center;
  margin: 0 auto;
  background-color: #f7f8fa;
}
.loading .position{
  position: absolute;
  right: 0;
  bottom: 40%;
  z-index: 333;
  width: 100%;
}
.loading .position p{
  display: inline-block;
}

.vip{
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 2px 0px;
  border-radius: 3px 10px 10px 3px;
  text-align: left;
}
.vip0{
  color: #7a7978;
}
.vip1{
   color: #cd1b29;
}
.vip2{
   color: #b79b6c;
}
</style>
