<template>
  <div style='height:100%;'>
    <van-nav-bar
      title="《用户协议》"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <iframe
        class='iframe'
        style='width:100%;height:500;'
        scrolling='no'
        :src='url'
        frameborder='0'
        id='useragreement'
        onload='setIframeHeight(this)'
    >
    </iframe>
  </div>
</template>
<script>
export default {
  name: 'UserAgreement',
  data () {
    return {
      url: 'static/term/user_agreement_zh-cn.html'
    }
  },
  mounted () {
    function reinitIframe () {
      var iframe = document.getElementById('useragreement')
      try {
        var bHeight = iframe.contentWindow.document.body.scrollHeight
        var dHeight = iframe.contentWindow.document.documentElement.scrollHeight
        var height = Math.min(bHeight, dHeight)
        iframe.height = height
      } catch (ex) {}
    };
    setInterval(reinitIframe, 100)
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style>
</style>
