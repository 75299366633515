<template>
  <div style="display: none;"></div>
</template>
<script>
// import * as THREE from 'three'
export default {
  name: 'canvasVideo',
  inject: ['material'],
  data () {
    return {
      video: document.createElement('video')
    }
  },
  props: {
    videourl: String,
    isplay: {
      default () {
        return false
      }
    }
  },
  watch: {
    isplay () {
      if (this.isplay) {
        this.video.play()
        this.video.muted = ''
      } else {
        this.video.pause()
        this.video.currentTime = 0
        this.video.muted = 'muted'
      }
    },
    videourl (val) {
      this.getVideo(val)
    }
  },
  created () {
    this.getVideo(this.videourl)
  },
  mounted () {
    this.video.play()
  },
  methods: {
    getVideo (val) {
      this.video.src = val // 设置视频地址
      this.video.autoplay = 'autoplay' // 要设置播放
      this.video.muted = 'muted' // 静音
      this.video.loop = 'loop'
      this.video.crossOrigin = 'anonymous'
      if (this.isplay) {
        this.video.play()
      }
      this.material.video = this.video
    }
  },
  beforeDestroy () {
    this.video.pause()
    this.video = null
  }
}
</script>
