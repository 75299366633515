
import Edit from '../views/Template/Edit.vue'
import CardTemplateList from '../views/Template/CardTemplateList.vue'
import EditCard from '../views/Template/EditCard.vue'

const template = {
  path: '/EditTemplate',
  require: '/EditTemplate/CardTemplateList',
  component: Edit,
  children: [
    {
      path: '',
      name: 'CardTemplateList',
      meta: {
        title: 'Login',
        requireAuth: true
      },
      component: CardTemplateList
    },
    {
      path: 'EditCard/:name',
      name: 'EditCard',
      meta: {
        title: 'Login',
        requireAuth: true
      },
      component: EditCard
    }
  ]
}

export default template
