import user from '../../Basics/User'
const god = {
  templateName: 'cardTemplateVip06',
  imgSrc: 'card/template/vip02/cardTemplateVip06.png',
  desc: '星空',
  category: 'card',
  vipGrade: 2,
  attr: {
    textColor: {
      type: 'color',
      id: '',
      label: '文本颜色',
      value: '#fff'
    },
    bgImage: {
      type: 'image',
      id: '',
      label: '背景图片',
      value: 'card/material/starsky.jpg'
    },
    modelSrc: {
      type: 'model',
      id: '',
      label: '上传模型',
      value: 'model/Lang.gltf'
    }
  }
}
god.attr = Object.assign(god.attr, user)
export default god
