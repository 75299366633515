<template>
  <div>
    <van-nav-bar
      title="登录"
      right-text="注册"
      :left-arrow="false"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="interval"></div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        label="手机号"
        name="pattern"
        placeholder="手机号"
        :rules="[{ pattern, message: '请填写正确手机号' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">登录</van-button>
      </div>
    </van-form>
    <div class="row">
      <div class="phone_login" @click="Jump_phoneLogin">手机快捷登录</div>
      <div class="Forget_pwd" @click="Jump_ForgetPassword">忘记密码</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Md5 from 'js-md5'
import { Toast } from 'vant'
export default {
  name: 'UserLogin',
  data () {
    return {
      phone: '',
      password: '',
      pattern: /^1[0-9]{10}$/
    }
  },
  methods: {
    onClickLeft () {
      Toast('返回')
    },
    onClickRight () {
      Toast('注册')
      this.$router.push({ path: '/Login/RegisterPassword' })
    },
    onSubmit () {
      const phone = this.phone
      const password = Md5(this.password)
      const user = {
        mobileNo: phone,
        password: password
      }
      console.log('password:::', password)
      var fd = new FormData()
      fd.append('req', JSON.stringify(user))
      console.log(user)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      }
      axios
        .post('/user/login', fd, config)
        .then(res => {
          console.log(res)

          if (res.data.returnCode === '0') {
            this.$store.commit('setUserInfo', res.data.userInfo)
            this.collectCardIfChecked()
            this.$router.push({ path: '/UserReceiveCard' })
            Toast.success('登陆成功')
          } else if (res.data.returnCode === '003') {
            Toast.fail('用户名或密码错误')
          } else {
            Toast.fail('用户名或密码错误')
          }
        })
        .catch(res => {
          console.log(res)
          Toast('网络错误')
        })
    },
    Jump_phoneLogin () {
      this.$router.push({ path: '/Login/userPhoneLogin' })
    },
    Jump_ForgetPassword () {
      this.$router.push({ path: '/Login/ForgetPassword' })
    },
    collectCardIfChecked () {
      const cardId = this.$store.state.collectingCardId
      if (cardId) {
        const req = {
          encryptCardId: cardId
        }

        const fd = new FormData()
        console.log('req', req)
        fd.append('req', JSON.stringify(req))
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios
          .post('/card/collect', fd, config)
          .then(res => {
            console.log(res)
            if (res.data.returnCode === '0') {
              this.$store.commit('setCollectingCardId', '')
            } else if (res.data.returnCode === '3') {
              Toast.fail('用户状态异常')
            } else if (res.data.returnCode === '1') {
              Toast.fail('无法收藏自己的名片')
            } else if (res.data.returnCode === '2') {
              Toast.fail('名片已经被收藏')
            } else {
              Toast.fail('收藏名片失败')
            }
          })
          .catch(res => {
            console.log(res)
            Toast.fail('网络错误')
          })
      }
    }
  }
}
</script>

<style scoped>
.interval {
  width: 100%;
  height: 1.875rem;
}
.row {
  margin-top: 0.9375rem;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  line-height: 20px;
  color: #111;
  font-family: PingFangSC-regular;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.row .phone_login {
}
.row .Forget_pwd {
}
</style>
