<template>
  <div @vm-oimo-body="handleBody">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'space-object',
  inject: ['spaceVms'],

  data () {
    return { body: null }
  },

  mounted () {
    this.spaceVms.push(this)
  },

  beforeDestroy () {
    const index = this.spaceVms.indexOf(this)
    if (index > -1) this.spaceVms.splice(index, 1)
  },

  methods: {
    handleBody (e) {
      this.body = e.detail
    }
  }
}
</script>
