<template>
  <div>
    <van-nav-bar
      title="我的会员"
      right-text="按钮"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-tabs v-model="active" color="#000" @change="TabsChange">
      <van-tab title="VIP会员">

        <div class="product">
          <div class="product_text">
            VIP会员可以使用所有免费会员和VIP会员的模板
          </div>
          <div
            class="product_item "
            v-for="(vip, index) in VipPriceList"
            :key="index + 'vip'"
            :class="{ activeSvip: Vip == index }"
            @click="choiceProduct(index, vip.price)"
          >
            <div class="product_title">{{ vip.title }}</div>
            <div class="product_info">
              <span class="rmb">￥</span>
              <span class="price rmb">{{ vip.price / 100 }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="超级VIP会员">
        <div class="product">
          <div class="product_text">
            超级VIP会员可以使用所有免费会员、VIP会员及超级VIP的所有模板
          </div>
          <div
            class="product_item "
            v-for="(vip, index) in superVipList"
            :key="index + 'vip'"
            :class="{ activeSvip: superVip == index }"
            @click="choiceProduct(index)"
          >
            <div class="product_title ">包月/30天</div>
            <div class="product_info">
              <span class="rmb">￥</span>
              <span class="price rmb">60</span>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-submit-bar :price="price" button-text="提交订单" @submit="onSubmit" />
  </div>
</template>

<script>
export default {
  name: 'PurchaseVip',
  data () {
    return {
      active: 0,
      Vip: -1,
      superVip: -1,
      price: 0,
      VipPriceList: [
        {
          title: '包月/30',
          price: 3000
        },
        {
          title: '包月/30',
          price: 6000
        },
        {
          title: '包月/30',
          price: 9000
        }
      ],
      superVipList: [
        {
          title: '包月/30',
          price: 6000
        }
      ]
    }
  },
  created () {
    this.Vip = 0
    this.RefreshPrice()
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    onClickRight () {},
    choiceProduct (index) {
      switch (this.active) {
        case 0:
          this.Vip = index
          break
        case 1:
          this.superVip = index
          break

        default:
          break
      }
      this.RefreshPrice()
    },
    TabsChange (name) {
      console.log('name', name)
      switch (name) {
        case 0:
          this.Vip = 0
          this.superVip = -1
          break
        case 1:
          this.Vip = -1
          this.superVip = 0
          break

        default:
          break
      }
      this.RefreshPrice()
    },
    RefreshPrice () {
      // console.log(this.active, 'active')
      // console.log(this.Vip, 'this.vip')
      // console.log(this.superVip, 'this.superVip')
      switch (this.active) {
        case 0:
          if (this.Vip >= 0 && this.Vip <= this.VipPriceList.length - 1) {
            const info = this.VipPriceList[this.Vip]
            this.price = info.price
          } else {
            this.price = 0
          }
          break
        case 1:
          if (this.superVip >= 0 && this.superVip <= this.superVipList.length - 1) {
            const info = this.superVipList[this.superVip]
            this.price = info.price
          } else {
            this.price = 0
          }
          break

        default:
          this.price = 0
          break
      }
    },
    onSubmit () {}
  }
}
</script>
<style scoped>
.product {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.product_text {
  padding: 0.2rem;
  width: 100vw;
  /* justify-content: center; */
}
.product_item {
  position: relative;
  box-sizing: border-box;
  min-width: 160px;
  min-height: 110px;
  margin-top: 10px;
  margin-right: 12px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid #f5f6fa;
}
.product_title {
  width: 100%;
  height: 16px;
  line-height: 16px;
  margin: 14px auto 9px auto;
  text-align: center;
  color: #03081a;
  font-size: 16px;
  font-weight: 700;
}
.product_info {
  width: 100%;
  height: 32px;
  margin-bottom: 9px;
  text-align: center;
}
.product_info .rmb {
  line-height: 20px;
  font-size: 14px;
}
.rmb {
  color: #d6a652;
}
.product_info .price {
  line-height: 32px;
  font-size: 26px;
  font-weight: 700;
}
.price {
  width: 100%;
  height: 32px;
  margin-bottom: 9px;
  text-align: center;
}
.product_item.activeSvip {
  background: #fffcf5;
  border: 2px solid #eabb6a;
}
</style>
