import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import vueThree from './components/vue-three/vue_three_js'

import Vant from 'vant'
import 'vant/lib/index.less'
import './assets/iconfont/iconfont.css'
import template from './components/CardTemplate'

import { ColorPicker } from 'element-ui'
import VueCookies from 'vue-cookies'

import Axios from 'axios'
Axios.defaults.baseURL = '/api/'
Axios.defaults.withCredentials = true

Vue.use(ColorPicker, { size: 'small', zIndex: 4000 })

Vue.use(template)
Vue.use(vueThree)

Vue.use(Vant)
Vue.use(VueCookies)
Vue.$cookies.config('7d')
Vue.$cookies.set('theme', 'default')
Vue.$cookies.set('hover-time', '1s')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
