import user from '../../Basics/User'
const god = {
  templateName: 'cardTemplateVip31',
  imgSrc: 'card/template/vip00/cardTemplateVip31.png',
  desc: '疯狂奶牛',
  category: 'card',
  vipGrade: 0,
  attr: {
    textColor: {
      type: 'color',
      id: '',
      label: '文本颜色',
      value: '#fff'
    },
    bgImage: {
      type: 'image',
      id: '',
      label: '背景图片',
      value: 'card/material/scene10.jpg'
    },
    modelSrc: {
      type: 'model',
      id: '',
      label: '上传模型',
      value: 'model/cow.glb'
    }
  }
}
god.attr = Object.assign(god.attr, user)
export default god
