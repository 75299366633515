<template>
  <div class="register">
    <van-nav-bar
      title="注册"
      left-text="返回"
      left-arrow
      @click-left="ReturnPrevious"
    />
    <div style="width:100%;height:30px;"></div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        label="手机号"
        name="validator"
        placeholder="手机号"
        :rules="[{ validator, message: '请填写正确手机号' }]"
      />
      <van-field
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button>
          <van-button
            @click="SendVerification"
            :disabled="disabledSms"
            size="small"
            type="primary"
          >
            <div class="time">
              <div>发送验证码</div>
              <van-count-down
                v-show="disabledSms"
                ref="countDown"
                millisecond
                format="ss"
                :auto-start="false"
                @finish="finish"
                :time="time"
              ></van-count-down>
            </div>
          </van-button>
        </template>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[
          {
            validator: validatorPwd,
            message: '密码至少八位，至少一个字母和一个数字：'
          }
        ]"
      />
      <van-field
        v-model="confirmPassword"
        type="password"
        name="确认密码"
        label="确认密码"
        placeholder="确认密码"
        :rules="[{ validator: validatorConfirmPassword, message: errorMsg }]"
      />
      <div style="height:10px"></div>
      <van-field name="checkbox">
        <template #input>
          <van-checkbox name="1" v-model="checkbox" shape="square">
          <div class="checkbox-text">我已同意</div>
          </van-checkbox>
          <div class="checkbox-text">
            <router-link class to="/useragreement">《用户协议》</router-link>
            <router-link to="/privateprotect">《隐私协议》</router-link>
            <router-link to="/childprivacy">《儿童隐私协议》</router-link>
          </div>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="info" :disabled="!checkbox" native-type="submit"
          >注册</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
import Md5 from 'js-md5'
export default {
  name: 'register',
  data () {
    return {
      phone: '',
      sms: '',
      password: '',
      confirmPassword: '',
      errorMsg: '', // 错误提示信息
      time: 60000,
      disabledSms: false,
      checkbox: false
    }
  },
  methods: {
    validator (val) {
      return /^1[0-9]{10}$/.test(val)
    },
    ReturnPrevious () {
      this.$router.go(-1)
    },
    validatorPwd (val) {
      return /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(val)
    },
    validatorConfirmPassword () {
      if (this.confirmPassword === '') {
        this.errorMsg = '密码不能为空'
        return false
      } else if (this.password !== this.confirmPassword) {
        this.errorMsg = '两次密码不一致'
        return false
      }
      return true
    },
    onSubmit () {
      const phone = this.phone
      const password = Md5(this.password)
      const vCode = this.sms
      const user = {
        mobileNo: phone,
        password: password,
        verifyCode: vCode
      }
      console.log('this', user)
      var fd = new FormData()
      fd.append('req', JSON.stringify(user))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/user/register', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.$router.push({ path: '/Login' })
            Toast.success('注册成功')
          } else if (res.data.returnCode === '002') {
            Toast.fail('用户已存在')
          } else if (res.data.returnCode === '004') {
            Toast.fail('验证码错误')
          } else {
            Toast.fail('注册测失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    },
    SendVerification () {
      this.start()

      var fd = new FormData()
      fd.append('phoneNo', this.phone)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/sms/registerCode', fd, config)
        .then(res => {
          console.log(res)
          // if (res.data.returnCode === '0') {
          //   this.$router.push({ path: '/Login' })
          //   Toast.success('注册成功')
          // } else if (res.data.returnCode === '002') {
          //   Toast.fail('用户已存在')
          // } else {
          //   Toast.fail('注册测失败')
          // }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
      this.disabledSms = true
    },
    start () {
      this.$refs.countDown.start()
    },
    reset () {
      this.$refs.countDown.reset()
    },
    finish () {
      this.disabledSms = false
      this.reset()
    }
  }
}
</script>

<style scoped>
.time {
  display: flex;
}
.time >>> .van-count-down {
  color: aliceblue;
  font-size: 12px;
  line-height: 1;
}
.register >>> .van-field__control--custom{
  overflow: hidden;
  flex-wrap: nowrap;
}
.register >>> .van-checkbox{
 /* width: 6.25rem; */

}
.checkbox-text{
  width: auto;
  font-size: .75rem;
  word-break:keep-all;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
</style>
