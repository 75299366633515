import Vue from 'vue'
import VueRouter from 'vue-router'

import loginRoute from './login.js'
import homeRoute from './home.js'
import templateRoute from './Template.js'
import PreviewCard from './preview.js'

import PurchaseVip from '../views/PurchaseVip/PurchaseVip.vue'

import ContactMe from '../views/ContactMe/ContactMe.vue'
import UserAgreement from '../views/UserAgreement/UserAgreement.vue'
import PrivateProtect from '../views/PrivateProtect/PrivateProtect.vue'
import ChildPrivacy from '../views/ChildPrivacy/ChildPrivacy.vue'
import ErrPage from '../views/ErrPage/ErrPage.vue'
Vue.use(VueRouter)

const routes = [
  homeRoute,
  loginRoute,
  templateRoute,
  PreviewCard,
  {
    path: '/PurchaseVip',
    name: 'PurchaseVip',
    meta: {
      title: 'Login',
      requireAuth: true
    },
    component: PurchaseVip
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contactme',
    name: 'ContactMe',
    meta: {
      title: 'Login',
      requireAuth: false
    },
    component: ContactMe
  },
  {
    path: '/useragreement',
    name: 'UserAgreement',
    meta: {
      title: 'Login',
      requireAuth: false
    },
    component: UserAgreement
  },
  {
    path: '/privateprotect',
    name: 'PrivateProtect',
    meta: {
      title: 'Login',
      requireAuth: false
    },
    component: PrivateProtect
  },
  {
    path: '/childprivacy',
    name: 'ChildPrivacy',
    meta: {
      title: 'Login',
      requireAuth: false
    },
    component: ChildPrivacy
  },
  {
    path: '*',
    component: ErrPage
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // requireAuth:可以在路由元信息指定哪些页面需要登录权限
    const userToken = Vue.$cookies.get('_business_card_user_token')

    if (userToken) {
      next()
    } else {
      next('/Login')
    }
  } else {
    next()
  }
})

export default router
