<template>
  <component :is="name" :attr="attr" :editStatus="editStatus"></component>
</template>

<script>
export default {
  name: 'Preview',
  props: {
    name: {
      type: String,
      required: true,
      default () {
        return ''
      }
    },
    attr: {
      type: Object,
      default () {
        return {}
      }
    },
    editStatus: {
      type: String,
      required: true,
      default () {
        return 'false'
      }
    }
  },
  created () {
  },
  data () {
    return {
    }
  }
}
</script>

<style></style>
