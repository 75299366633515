<template>
  <div>
    <text-row :row-id="attr.id"
              :row-class="attr.rowClass"
              :icon-show="attr.iconShow"
              :icon-class="attr.class"
              :icon-name="attr.iconName"
              :text-class="attr.textClass"
              :text-value="attr.value">
    </text-row>
  </div>

</template>

<script>
import TextRow from '../planar/textRow'
export default {
  name: 'infoRow',
  components: {
    TextRow
  },
  props: {
    attr: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
