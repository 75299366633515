import user from '../../Basics/User'
const god = {
  templateName: 'cardTemplateVip103',
  imgSrc: 'card/template/vip02/cardTemplateVip03.png',
  desc: '奔腾草原狼',
  category: 'card',
  vipGrade: 2,
  attr: {
    textColor: {
      type: 'color',
      id: '',
      label: '文本颜色',
      value: '#000'
    },
    bgColor: {
      type: 'color',
      id: '',
      label: '背景颜色',
      value: '#ccf1fe'
    },
    modelSrc: {
      type: 'model',
      id: '',
      label: '上传模型',
      value: 'model/Lang.gltf'
    }
  }
}
god.attr = Object.assign(god.attr, user)
export default god
