<script>
import Base from '../components/Base'
import ModelStorage from './ModelStorage'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

export default {
  name: 'MGltf',
  mixins: [Base, ModelStorage],
  inject: ['meshVm'],
  props: {
    process: Function,
    url: String,
    edition: Number
  },
  data () {
    return {
      dracoLoader: null,
      loader: null,
      models: {},
      meshName: 'HDM_01_04_trunk'
    }
  },
  created () {
    this.initial(this.url)
  },
  watch: {
    url () {
      this.initial(this.url)
    }
  },
  beforeDestroy () {
    if (this.loader) {
      if (typeof this.loader.dispose === 'function') {
        this.loader.dispose()
      }
    }

    if (this.dracoLoader) {
      this.dracoLoader.dispose()
    }
    this.loader = null
    this.dracoLoader = null
  },
  destroyed () {
    const arr = this.meshVm.curObj.children.filter(x => x)
    arr.forEach(a => {
      this.dispose(this.meshVm.curObj, a)
    })
    this.meshVm.curObj = null
  },
  methods: {
    dispose (parent, child) {
      if (child.children.length) {
        const arr = child.children.filter(x => x)
        arr.forEach(a => {
          this.dispose(child, a)
        })
      }
      if (child.material) {
        if (child.material.map) {
          child.material.map.dispose()
        }
        child.material.dispose()
      }
      if (child.geometry) {
        child.geometry.dispose()
      }
      child.remove()
      parent.remove(child)
    },
    // 读取模型
    /** 该方法用于读取模型文件
     **/
    requireModel (url) {
      // 读取数据  console.log("读取数据");
      console.log('enter requireModel')
      const scope = this
      this.loader = new GLTFLoader()
      if (this.baseUrl) {
        this.loader.setPath(this.baseUrl)
      }
      this.dracoLoader = new DRACOLoader()
      this.dracoLoader.setDecoderPath('static/threex/draco/gltf/')
      this.loader.setDRACOLoader(this.dracoLoader)
      console.log('before parse')
      this.loader.parse(url, '', gltf => {
        const object = gltf.scene
        object.traverse(function (node) {
          if (node.isMesh) {
            if (node.isMesh || node.isLight) node.castShadow = true
          }
        })
        //  Box3 方法的作用
        //  计算和世界轴对齐的一个对象 Object3D （含其子对象）的包围盒，计算对象和子对象的世界坐标变换。
        this.$emit('getModels', object)
        var objBbox = new THREE.Box3().setFromObject(gltf.scene)
        objBbox.setFromObject(object)
        const animations = gltf.animations
        // 加载动画返回动画
        this.animation(animations, object)
        //
        // Manipulate and Query Database
        //
        scope.meshVm.curObj = object
        console.log('emit loadingOver')
        this.$emit('loadingOver')
        this.dracoLoader.dispose()
        // object = null;
      })
    },
    getModel (model) {
      model.forEach(items => {
        if (items.name === this.meshName) {
          this.models = items
          return items
        } else {
          return this.getModel(items.children)
        }
      })
    }
  }
}
</script>

<style scoped></style>
