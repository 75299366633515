<script>
import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import Object3D from './../components/Object3D'
export default {
  name: 'cssSign',
  mixins: [Object3D],
  props: {
    size: {
      type: Object, // { w, h }
      default () {
        return { w: 50, h: 50 }
      }
    },
    src: {
      type: String,
      default () {
        return ''
      }
    },
    show: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    const curObj = new THREE.Group()
    const dom = document.createElement('div')
    dom.style.width = `${this.size.w}px`
    dom.style.height = `${this.size.h}px`
    const img = document.createElement('img')
    img.style.width = '100%'
    img.style.height = '100%'
    img.style.border = '0px'
    img.src = this.src
    dom.appendChild(img)
    dom.addEventListener('click', this.click)
    const object = new CSS2DObject(dom)
    curObj.add(object)
    return { curObj, dom }
  },
  methods: {
    click () {
      this.$emit('imgclick', this.dom)
    }
  },
  beforeDestroy () {
    this.$off('imgclick')
    this.dom.removeEventListener('click', this.click)
    this.dom = null
  },
  watch: {
    show (newShow) {
      if (newShow) {
        this.dom.style.width = `${this.size.w}px`
        this.dom.style.height = `${this.size.h}px`
      } else {
        this.dom.style.width = '0px'
        this.dom.style.height = '0px'
      }
    }
  }
}
</script>

<style></style>
