<template>
    <div></div>
</template>
<script>
import * as THREE from 'three'
export default {
  name: 'Fog',
  inject: ['scene'],
  props: {
    color: {
      type: [String, Number]

    },
    near: Number,
    far: Number
  },
  data () {
    var fog = new THREE.Fog(new THREE.Color(this.color), this.near, this.far)
    return { fog }
  },
  mounted () {
    this.scene.fog = this.fog
  },
  beforeDestroy () {
    this.fog = null
  }
}
</script>
