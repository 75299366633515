<script>
/* eslint-disable no-duplicate-imports */
import { Scene } from 'three'
import * as THREE from 'three'
import Object3D from './Object3D'

export default {
  name: 'Scene',
  mixins: [Object3D],
  props: {
    obj: { type: Object },
    bgcolor: {
      type: Number,
      default () {
        return 0
      }
    },
    gridHelper: {
      type: Boolean,
      default () {
        return true
      }
    },
    gridHelpers: {
      type: Boolean,
      default () {
        return true
      }
    },
    childrenObj: {
      type: Object,
      default () {
        return null
      }
    }
  },
  provide () {
    return {
      scene: this.curObj
    }
  },
  inject: {
    renderer: { name: 'renderer', default: null }
  },
  data () {
    let curObj = this.obj
    if (!curObj) {
      curObj = new Scene()
    }
    if (this.bgcolor) {
      curObj.background = new THREE.Color(this.bgcolor)

      curObj.name = curObj.name || curObj.type
    }
    return { curObj }
  },

  mounted () {
    const scene = this.curObj
    if (this.gridHelper) {
      const axes = new THREE.AxisHelper(120)
      scene.add(axes)
      const gridHelper = new THREE.GridHelper(300, 20, 0x2c2c2c, 0x888888)
      gridHelper.position.y = 0
      scene.add(gridHelper)
    }
    this.renderer.scene = scene
    if (!this.$global.scene) {
      this.$global.scene = scene
    }

    // for threejs-inspector to work
    // https://github.com/jeromeetienne/threejs-inspector
    // if (process.env.NODE_ENV === "development") {
    //   window.THREE = THREE;
    //   window.scene = scene;
    // }
  },
  destroyed () {
    const arr = this.curObj.children.filter(x => x)
    arr.forEach(a => {
      this.dispose(this.curObj, a)
    })
    this.curObj.remove()
    this.curObj = null
  },

  methods: {
    dispose (parent, child) {
      if (child.children.length) {
        const arr = child.children.filter(x => x)
        arr.forEach(a => {
          this.dispose(child, a)
        })
      }
      if (child.material) {
        if (child.material.map) {
          child.material.map.dispose()
        }
        child.material.dispose()
      }
      if (child.geometry) {
        child.geometry.dispose()
      }
      child.remove()
      parent.remove(child)
    }
  }
}
</script>
