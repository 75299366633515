<template>
  <div>
    <div style="height:15px"></div>
    <div id="card" class="card">
      <div class="content" v-show="!isShow">
        <div class="text name" :id="attr.userName.id" :contenteditable="editStatus">
          {{ attr.userName.value }}
        </div>
        <div
          class="font20 text occupation"
          :id="attr.officePosition.id"
          :contenteditable="editStatus"
        >
          {{ attr.officePosition.value }}
        </div>
        <div class="info">
          <div class="row">
            <span class="icon">
              <van-icon name="phone" />
            </span>
            <span
              class="phone text"
              :id="attr.mobileNo.id"
              :contenteditable="editStatus"
              >{{ attr.mobileNo.value }}</span
            >
          </div>
          <div class="row">
            <span class="icon">
              <van-icon name="map-marked" />
            </span>
            <span
              class="marked text"
              :id="attr.companyAddress.id"
              :contenteditable="editStatus"
              >{{ attr.companyAddress.value }}</span
            >
          </div>
          <div class="row">
            <span class="icon">
              <van-icon name="envelop-o" />
            </span>
            <span class="chat text" :id="attr.email.id" :contenteditable="editStatus">{{
              attr.email.value
            }}</span>
          </div>
        </div>
      </div>
      <div class="scene">
        <background :modelSrc="attr.modelSrc.value"></background>
      </div>
      <div class="btn">
        <van-icon v-show="!isShow" name="eye-o" @click="isShow=true" />
        <van-icon v-show="isShow" name="closed-eye" @click="isShow=false" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardTemplateVip02',
  props: {
    attr: {
      type: Object,
      default () {
        return {}
      }
    },
    editStatus: {
      type: String,
      default () {
        return 'false'
      }
    }
  },
  data () {
    return {
      isShow: false
    }
  },
  watch: {
    attr: {
      deep: true,
      handler (newValue, oldValue) {
        this.update()
      }

    }
  },
  created () {
    this.$nextTick(() => {
      this.update()
    })
  // console.log(this.attr)
  },
  methods: {
    onChange () {
    },
    update () {
      this.getClassName('card', (element) => {
        element.style.backgroundImage = 'url(' + this.attr.bgImage.value + ')'
        element.style.backgroundSize = 'cover'
      })
      this.getClassName('btn', (element) => {
        element.style.color = this.attr.textColor.value
      })
      this.getClassName('info', (element) => {
        element.style.color = this.attr.textColor.value
      })
      //  dom.style.color = this.attr.textColor.value
      this.getById((dom) => {
        dom.style.color = this.attr.textColor.value
      })
    },
    getClassName (name, fn) {
      const domlist = document.getElementsByClassName(name)
      for (let i = 0; i < domlist.length; i++) {
        const element = domlist[i]
        if (element) {
          fn(element)
        }
      }
    },
    getById (fn) {
      for (const key in this.attr) {
        if (Object.hasOwnProperty.call(this.attr, key)) {
          const element = this.attr[key]
          const dom = document.getElementById(element.id)
          if (dom) {
            fn(dom)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.card {
  width: 22.5rem;
  height: 13.75rem;
  margin: 15px auto;
  background: #000000;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.content {
  padding: 1.875rem;
  position: relative;
  z-index: 100;
}
.text {
  cursor: move;
  line-height: 1.3;
  letter-spacing: 0px;
  font-family: font169, font130;
  color: rgb(255, 255, 255);
  word-break: break-all;
  writing-mode: horizontal-tb;
  opacity: 1;
}
.content .name {
  font-size: 2.2rem;
  font-weight: bold;
}
.content .occupation {
  font-size: 0.875rem;
}
.font20 {
  font-size: 1.25rem;
}
.info {
  padding-top: 1.25rem;
  color: aliceblue;
}
.info .row {
  margin: 0.375rem 0;
}
.info .text {
  padding-left: 0.625rem;
}
.info .icon {
  position: relative;
  top: 0.125rem;
}

.scene{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.btn{
  position: absolute;
  right: .625rem;
  bottom: .5rem;
  z-index: 181;
  color: aliceblue;
}
</style>
