import { render, staticRenderFns } from "./cardTemplateVip15.vue?vue&type=template&id=314b899c&scoped=true&"
import script from "./cardTemplateVip15.vue?vue&type=script&lang=js&"
export * from "./cardTemplateVip15.vue?vue&type=script&lang=js&"
import style0 from "./cardTemplateVip15.vue?vue&type=style&index=0&id=314b899c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314b899c",
  null
  
)

export default component.exports