<template>
  <Object3D :rotation="{ y: Math.PI }">
    <slot></slot>
  </Object3D>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Object3D from '../components/Object3D'
import { Clock } from 'three'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js'
export default {
  name: 'OrbitControls',
  mixins: [Object3D],
  components: { Object3D },
  inject: {
    renderer: { name: 'renderer', default: null }
  },
  data () {
    return {
      controls: null,
      timer: null,
      frame: null
    }
  },
  props: {
    enableDamping: {
      // 指定是否使用阻尼
      typeof: Boolean,
      default () {
        return true
      }
    },
    dampingFactor: {
      // 动态阻尼系数
      typeof: Number,
      default () {
        return 0.05
      }
    },
    enableZoom: {
      // 缩放
      typeof: Boolean,
      default () {
        return true
      }
    },
    autoRotate: {
      // 是否自动旋转
      typeof: Boolean,
      default () {
        return true
      }
    },
    minDistance: {
      // 相机距离原点的最近距离
      typeof: Number,
      default () {
        return 0
      }
    },
    maxDistance: {
      // 相机距离远点的最远距离
      typeof: Number,
      default () {
        return 1
      }
    },
    target: {
      // 控制器的焦点，camera的轨道围绕它运行。
      typeof: Object,
      default () {
        return { x: 0, y: 100, z: 0 }
      }
    },
    enablePan: {
      //  禁用鼠标右键平移
      typeof: Boolean,
      default () {
        return false
      }
    },
    panSpeed: {
      //  camera 平移速度
      typeof: Number,
      default () {
        return 1
      }
    },
    rotateSpeed: {
      //  camera 旋转速度
      typeof: Number,
      default () {
        return 1
      }
    },
    // 围绕物体的旋转速度
    autoRotateSpeed: {
      type: Number,
      default () {
        return 1
      }
    },
    angle: {
      //  camera 角度
      typeof: Number,
      default () {
        return 0.5
      }
    },
    minPolarAngle: {
      //  camera 你能够垂直旋转的角度的下限，范围是0到Math.PI，其默认值为0。
      typeof: Number,
      default () {
        return 0
      }
    }
  },
  watch: {
    autoRotate () {
      this.setControls()
    },
    target () {
      this.setControls()
    }
  },
  mounted () {
    this.initControls()

    this.timer = new Clock()
    this.frame = this.animate()

    const _this = this
    this.$bus.$on('emitCamera', () => {
      _this.cameraCon(
        { x: -45, y: 14, z: -15 },
        { x: -45, y: 14, z: -15 },
        { x: 10, y: 14, z: 10 },
        { x: 0, y: 13, z: 0 }
      )
    })
  },
  beforeDestroy () {
    if (this.frame) {
      cancelAnimationFrame(this.frame)
    }
    if (this.controls) {
      this.controls.dispose()
      this.controls = null
    }
  },

  methods: {
    cameraCon (oldP, oldT, newP, newT) {
      const tween = new TWEEN.Tween({
        x1: oldP.x, //  相机x
        y1: oldP.y, //  相机y
        z1: oldP.z, //  相机z
        x2: oldT.x, //  控制点的中心点x
        y2: oldT.y, //  控制点的中心点y
        z2: oldT.z //  控制点的中心点z
      })
      tween.to(
        {
          x1: newP.x,
          y1: newP.y,
          z1: newP.z,
          x2: newT.x,
          y2: newT.y,
          z2: newT.z
        },
        2000
      )
      const _this = this
      if (_this.controls) {
        tween.onUpdate(function (object) {
          _this.controls.target.x = object.x2
          _this.controls.target.y = object.y2
          _this.controls.target.z = object.z2
          _this.controls.minDistance = 0
          _this.controls.maxDistance = 1
          _this.$global.camera.position.x = object.x1
          _this.$global.camera.position.y = object.y1
          _this.$global.camera.position.z = object.z1
        })
      }

      tween.easing(TWEEN.Easing.Cubic.InOut)
      tween.start()
    },
    noChange () {
      this.controls.maxDistance = this.maxDistance
      this.controls.minDistance = this.minDistance
    },
    closeControls (val) {
      this.controls.autoRotate = val
    },
    animate () {
      this.frame = requestAnimationFrame(this.animate)
      TWEEN.update()
      if (this.controls) {
        this.controls.update(this.timer.getDelta())
      }
    },
    initControls () {
      const domElement = this.renderer.div //  fixme this.$global.camera
      const camera = this.renderer.camera
      this.controls = new OrbitControls(camera, domElement)
      this.setControls()
    },
    setControls () {
      this.controls.enableDamping = this.enableDamping // 指定是否使用阻尼
      this.controls.dampingFactor = this.dampingFactor // 动态阻尼系数
      this.controls.enableZoom = this.enableZoom // 缩放
      this.controls.autoRotate = this.autoRotate // 是否自动旋转
      this.controls.rotateSpeed = this.rotateSpeed // 旋转速度
      this.controls.autoRotateSpeed = this.autoRotateSpeed // 围绕物体的旋转速度
      this.controls.maxPolarAngle = Math.PI * this.angle // 相机最大角度
      this.controls.minPolarAngle = Math.PI * this.minPolarAngle
      this.controls.minDistance = this.minDistance // 相机距离原点的最近距离
      this.controls.maxDistance = this.maxDistance // 相机距离远点的最远距离
      const position = new THREE.Vector3()
      position.x = Number(this.target.x)
      position.y = Number(this.target.y)
      position.z = Number(this.target.z)
      this.controls.target = position
      this.controls.enablePan = this.enablePan //  禁用鼠标右键
      this.controls.panSpeed = this.panSpeed //  camera 平移速度
    }
  }
}
</script>
