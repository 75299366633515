<template>
  <div>
    <slot></slot>
    <animation :fn="uniGravity"></animation>
  </div>
</template>

<script>
import Animation from '../components/Animation'

export default {
  name: 'space-system',
  components: { Animation },
  props: {
    mScale: { type: Number, default: 1 }
  },

  provide () {
    return { spaceVms: this.spaceVms }
  },

  data () {
    return { spaceVms: [] }
  },

  methods: {
    uniGravity () {
      // apply universal gracity
      // https://en.wikipedia.org/wiki/Gravitational_constant
      const G = 6.67408 * (10 ** -11)
      const arr = this.spaceVms
      const len = arr.length

      for (let i = 0; i < len; i++) {
        for (let j = i + 1; j < len - i; j++) {
          const [vm1, vm2] = [i, j].map(k => arr[k])
          const [b1, b2] = [vm1, vm2].map(vm => vm.body)
          if (!b1 || !b2) continue

          const [m1, m2] = [b1, b2].map(b => b.mass)
          const [p1, p2] = [b1, b2].map(b => b.getPosition())
          const fv1 = p2.clone().sub(p1)
          const fvn1 = fv1.normalize()

          const r = fv1.length()
          let F = G * m1 * m2 / (r ** 2)
          F = F * (this.mScale ** 2) // scale

          const F1 = fvn1.clone().multiplyScalar(F)
          const F2 = F1.clone().negate()
          b1.applyImpulse(p1, F1)
          b2.applyImpulse(p2, F2)
        }
      }
    }
  }
}
</script>
