<template>
  <div>
    <Object3D v-if="audio" :obj="audio"> </Object3D>
  </div>
</template>

<script>
import { PositionalAudio, AudioLoader } from 'three'
import Object3D from './Object3D'

export default {
  name: 'PositionalAudio',
  mixins: [Object3D],
  components: { Object3D },
  props: {
    refDistance: { type: Number, default: 15 },
    volume: { type: Number, default: 1 },
    paused: Boolean,
    loop: Boolean,
    url: String
  },

  data () {
    return { audio: null }
  },

  watch: {
    paused (v) {
      if (this.audio) {
        if (v) this.audio.pause()
        else this.audio.play()
      }
    }
  },

  // mounted to ensure audioListener
  mounted () {
    const listener = this.$global.audioListener // fixme
    const audio = new PositionalAudio(listener)
    audio.name = 'PositionalAudio'
    audio.setRefDistance(this.refDistance)
    audio.setVolume(this.volume)
    audio.setLoop(this.loop)
    new AudioLoader().load(this.url, buffer => {
      audio.setBuffer(buffer)
      if (!this.paused) audio.play()
    })
    this.audio = audio
  },
  methods: {
    vloumn () {
      console.log(this.paused)
    }
  },
  computed: {},
  beforeDestroy () {
    this.audio.pause()
    this.audio = null
  }
}
</script>
