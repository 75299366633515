<script>
import Object3D from './../components/Object3D'
import * as THREE from 'three'
export default {
  name: 'SpotLight',
  mixins: [Object3D],
  inject: ['scene'],
  props: {
    hex: { type: String || Number }, // color
    intensity: Number, // intensity - (可选参数) 光照强度。 缺省值 1。
    distance: Number, // distance - 从光源发出光的最大距离，其强度根据光源的距离线性衰减。
    angel: Number, // angle - 光线散射角度，最大为Math.PI/2。
    decay: Number, // decay - 沿着光照距离的衰减量
    penumbra: {
      // 模糊程度
      type: Number,
      default () {
        return 1
      }
    },
    targetPosition: {
      // 灯光指向位置
      type: Object,
      default () {
        return { x: 0, y: 1, z: 0 }
      }
    },
    near: {
      type: Number,
      default () {
        return 10
      }
    },
    far: {
      type: Number,
      default () {
        return 200
      }
    },
    focus: {
      type: Number,
      default () {
        return 1
      }
    },
    mapSize: {
      type: Object, // { w, h }
      default () {
        return { w: 2048, h: 2048 }
      }
    },
    castShadow: {
      type: Boolean, // { w, h }
      default () {
        return true
      }
    }
  },
  data () {
    let curObj = this.obj
    if (!curObj) {
      curObj = new THREE.SpotLight(
        new THREE.Color(this.hex),
        this.intensity,
        this.distance,
        this.angel,
        0,
        this.decay
      )
    }
    curObj.target = new THREE.Object3D()
    curObj.target.position.x = this.targetPosition.x
    curObj.target.position.y = this.targetPosition.y
    curObj.target.position.z = this.targetPosition.z
    curObj.name = curObj.name || curObj.type
    // 阴影
    curObj.penumbra = this.penumbra
    curObj.castShadow = this.castShadow
    curObj.shadow.mapSize.width = this.mapSize.w
    curObj.shadow.mapSize.height = this.mapSize.h
    curObj.shadow.camera.near = this.near
    curObj.shadow.camera.far = this.far
    curObj.shadow.focus = this.focus

    return { curObj }
  },
  created () {},
  mounted () {
    this.scene.add(this.curObj.target)
  }
}
</script>
