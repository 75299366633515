import Home from '../views/Home/Home.vue'
import UserBusinessCard from '../views/Home/UserBusinessCard.vue'
import UserReceiveCard from '../views/Home/UserReceiveCard.vue'
import UserInfo from '../views/Home/UserInfo.vue'

const home = {
  path: '/',
  name: 'Home',
  component: Home,
  redirect: '/UserReceiveCard',
  meta: {
    title: 'Login',
    requireAuth: true
  },
  children: [
    {
      path: 'UserInfo',
      name: 'UserInfo',
      meta: {
        title: 'Login',
        requireAuth: true
      },
      component: UserInfo
    },
    {
      path: 'UserBusinessCard',
      name: 'UserBusinessCard',
      meta: {
        title: 'Login',
        requireAuth: true
      },
      component: UserBusinessCard
    },
    {
      path: 'UserReceiveCard',
      name: 'UserReceiveCard',
      meta: {
        title: 'Login',
        requireAuth: true
      },
      component: UserReceiveCard
    }
  ]
}

export default home
