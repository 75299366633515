<script>
import * as THREE from 'three'
import Object3D from './../components/Object3D'
import Animation from '../components/Animation.vue'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js'
export default {
  components: { Animation },
  name: 'snow',
  mixins: [Object3D],
  props: {
    snow: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  watch: {
    snow (v) {
      if (v) {
        this.start()
      } else {
        this.close()
      }
    }
  },
  data () {
    const materials = []
    var curObj = new THREE.Group()
    const geometry = new THREE.BufferGeometry()
    const vertices = []
    const normals = []

    const textureLoader = new THREE.TextureLoader()

    const sprite1 = textureLoader.load('static/snow/snowflake1.png')
    const sprite2 = textureLoader.load('static/snow/snowflake2.png')
    const sprite3 = textureLoader.load('static/snow/snowflake3.png')
    const sprite4 = textureLoader.load('static/snow/snowflake4.png')
    const sprite5 = textureLoader.load('static/snow/snowflake5.png')

    for (let i = 0; i < 10000; i++) {
      const x = Math.random() * 2000 - 1000
      const y = Math.random() * 2000 - 1000
      const z = Math.random() * 2000 - 1000
      const H = 0.1 + Math.random() / 15
      const I = (Math.random() - 0.5) / 3
      normals.push(H, I, z)
      vertices.push(x, y, z)
    }

    geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3)
    )
    geometry.setAttribute(
      'normals',
      new THREE.Float32BufferAttribute(normals, 3)
    )

    const parameters = [
      [[1.0, 0.2, 0.5], sprite2, 20],
      [[0.95, 0.1, 0.5], sprite3, 15],
      [[0.9, 0.05, 0.5], sprite1, 10],
      [[0.85, 0, 0.5], sprite5, 8],
      [[0.8, 0, 0.5], sprite4, 5]
    ]

    for (let i = 0; i < parameters.length; i++) {
      const color = parameters[i][0]
      const sprite = parameters[i][1]
      const size = parameters[i][2]

      materials[i] = new THREE.PointsMaterial({
        size: size,
        map: sprite,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true
      })
      materials[i].color.setHSL(color[0], color[1], color[2])

      const particles = new THREE.Points(geometry, materials[i])

      particles.rotation.x = Math.random() * -6
      particles.rotation.y = Math.random() * -6
      particles.rotation.z = Math.random() * -6
      curObj.add(particles)
    }

    return { curObj, materials, parameters, frame: null }
  },
  mounted () {
    if (this.snow) {
      this.animation()
    } else {
      this.curObj.visible = false
    }
  },
  methods: {
    animation () {
      this.frame = requestAnimationFrame(this.animation)
      const time = Date.now() * 0.00005
      for (let i = 0; i < this.curObj.children.length; i++) {
        const object = this.curObj.children[i]
        if (object instanceof THREE.Points) {
          object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1))
          var position = object.geometry.getAttribute('position')
          var normals = object.geometry.getAttribute('normals')
          for (let i = 0; i < position.count; i += 3) {
            const ix = position.getX(i)
            const iy = position.getY(i)
            // const iz = position.getZ(i)
            const nx = normals.getX(i)
            const ny = normals.getY(i)
            const nz = normals.getZ(i)
            const vx = ix - nx
            const vy = iy - ny
            // let vz = iz - nz;

            if (vy <= -5000) {
              position.setXYZ(i, vx, 500, nz)
            } else {
              position.setXY(i, vx, vy, nz)
            }
            if (vx <= -20 || vx >= 20) {
              normals.setX(i, nx * -1)
              normals.needsUpdate = true
            }
            position.needsUpdate = true
          }
        }
      }

      for (let i = 0; i < this.materials.length; i++) {
        const color = this.parameters[i][0]
        const h = ((360 * (color[0] + time)) % 360) / 360
        this.materials[i].color.setHSL(h, color[1], color[2])
      }
    },
    end () {
      if (this.frame) {
        cancelAnimationFrame(this.frame)
      }
    },
    start () {
      this.setScale(this.scale)
      this.curObj.visible = true
      this.animation()
    },
    close () {
      const tween = new TWEEN.Tween(this.curObj.scale)
      tween.to({ y: 0 }, 2000)
      tween.easing(TWEEN.Easing.Cubic.InOut)
      const _this = this
      tween.onUpdate(function (object) {
        _this.curObj.scale.y = object.y
      })
      tween.start()
      tween.onComplete(function () {
        _this.curObj.visible = false
        _this.end()
      })
    }
  }
}
</script>

<style></style>
