<template>
  <div>
    <router-view></router-view>
<!--    <div style="height:50px"></div>-->
    <van-tabbar v-model="active" :fixed="true" :placeholder="true">
      <van-tabbar-item to='/UserReceiveCard' icon-prefix='iconfont' icon='iconset0327'>名片夹</van-tabbar-item>
      <van-tabbar-item to='/UserBusinessCard' icon-prefix='iconfont' icon='mingpian'>我的名片</van-tabbar-item>
      <van-tabbar-item to='/UserInfo' icon-prefix='iconfont' icon='tubiaozhuanqu-04'>我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      active: 0
    }
  },
  watch: {
  },
  created () {
    this.pathChange(this.$route.path)
  },
  mounted () {
    console.log('setEditingCard Empty')
    this.$store.commit('setEditingCard', {})
    this.pathChange(this.$route.path)
  },
  methods: {
    onChange (index) {
      switch (index) {
        case 0:
          if (this.$route.path !== '/UserReceiveCard') {
            this.$router.push({ path: '/UserReceiveCard' })
          }
          break
        case 1:
          if (this.$route.path !== '/UserBusinessCard') {
            this.$router.push({ path: '/UserBusinessCard' })
          }

          break
        case 2:
          if (this.$route.path !== '/UserInfo') {
            this.$router.push({ path: '/UserInfo' })
          }
          break

        default:
          break
      }
    },
    pathChange (path) {
      switch (path) {
        case '/UserReceiveCard':
          this.active = 0
          break
        case '/UserBusinessCard':
          this.active = 1
          break
        case '/UserInfo':
          this.active = 2
          break

        default:
          break
      }
    }
  }
}
</script>

<style>

</style>
