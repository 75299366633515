import user from '../../Basics/User'
const god = {
  templateName: 'cardTemplateVip101',
  imgSrc: 'card/template/vip00/cardTemplateVip01.png',
  desc: '春暖花开，花卉',
  category: 'card',
  vipGrade: 0,
  attr: {
    // 额外
    modelSrc: {
      type: 'model',
      id: '',
      label: '上传模型',
      value: 'model/fragrantgarlic.gltf'
    },
    textColor: {
      type: 'color',
      id: '',
      label: '文本颜色',
      value: '#fff'
    },
    bgImage: {
      type: 'image',
      id: '',
      label: '上传图片',
      value: 'card/material/4.jpg'
    }
  }
}
god.attr = Object.assign(god.attr, user)
export default god
