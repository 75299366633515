<template>
  <div class="demo">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="product-grid"   id="card">
            <div class="scene">
              <background :distance="0.7" :modelSrc="attr.modelSrc.value"></background>
            </div>
            <div class="product-image">
              <img :src="attr.bgImage.value"/>
            </div>
            <div class="btn">
                <van-icon v-show="!isShow" name="eye-o" @click="isShow=true" />
                <van-icon v-show="isShow" name="closed-eye" @click="isShow=false" />
            </div>
            <div class="content" v-show="!isShow">
              <div class="content-text">
                <div
                  class="text name"
                  :id="attr.userName.id"
                  :contenteditable="editStatus"
                >
                  {{ attr.userName.value }}
                </div>
                <div
                  class="font20 text occupation"
                  :id="attr.officePosition.id"
                  :contenteditable="editStatus"
                >
                  {{ attr.officePosition.value }}
                </div>
                <div class="info">
                  <div class="row">
                    <span class="icon">
                      <van-icon name="phone" />
                    </span>
                    <span
                      class="phone text"
                      :id="attr.mobileNo.id"
                      :contenteditable="editStatus"
                      >{{ attr.mobileNo.value }}</span
                    >
                  </div>
                  <div class="row">
                    <span class="icon">
                      <van-icon name="map-marked" />
                    </span>
                    <span
                      class="marked text"
                      :id="attr.companyAddress.id"
                      :contenteditable="editStatus"
                      >{{ attr.companyAddress.value }}</span
                    >
                  </div>
                  <div class="row">
                    <span class="icon">
                     <van-icon name="envelop-o" />
                    </span>
                    <span
                      class="chat text"
                      :id="attr.email.id"
                      :contenteditable="editStatus"
                      >{{ attr.email.value }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardTemplateVip20',
  data () {
    return {
      isShow: false
    }
  },
  props: {
    attr: {
      type: Object,
      default () {
        return {}
      }
    },
    editStatus: {
      type: String,
      default () {
        return 'false'
      }
    }
  },
  watch: {
    attr: {
      deep: true,
      handler (newValue, oldValue) {
        this.update()
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.update()
    })
    // console.log(this.attr)
  },
  methods: {
    onChange () {},
    update () {
      this.getClassName('btn', element => {
        element.style.color = this.attr.textColor.value
      })
      this.getClassName('info', element => {
        element.style.color = this.attr.textColor.value
      })
      //  dom.style.color = this.attr.textColor.value
      this.getById(dom => {
        dom.style.color = this.attr.textColor.value
      })
    },
    getClassName (name, fn) {
      const domlist = document.getElementsByClassName(name)
      for (let i = 0; i < domlist.length; i++) {
        const element = domlist[i]
        if (element) {
          fn(element)
        }
      }
    },
    getById (fn) {
      for (const key in this.attr) {
        if (Object.hasOwnProperty.call(this.attr, key)) {
          const element = this.attr[key]
          const dom = document.getElementById(element.id)
          if (dom) {
            fn(dom)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  margin-right: auto;
  margin-left: auto;
}
.col{
  min-width: 100vmin;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding-top: 0.5rem;
  overflow: hidden;
  padding-bottom: 3rem;
}
.row {
  margin: 0 auto;
  text-align: center;
}
.demo {
  padding: 50px 0px;
  background: #fff;
}
.product-grid {
  width: 22.5rem;
  margin: 0 auto;
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: left;
  overflow:visible;
}
.scene {
  position: absolute;
  top: -3rem;
  left:-3rem;
  z-index: 33;
  width: 28.5rem;
  height: 19.75rem;
}
.product-grid .product-image {
  width: 22.5rem;
  height: 13.75rem;
  position: relative;
  overflow: hidden;
}
.product-image img{
  width: 100%;
  height: 100%;
}

.product-grid .product-content {
  padding: 12px 0 0;
  position: relative;
  z-index: 44;
}
.product-grid .title {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.content-text {
  padding: 1.875rem;
}
.text {
  cursor: move;
  line-height: 1.3;
  letter-spacing: 0px;
  font-family: font169, font130;
  color: rgb(255, 255, 255);
  word-break: break-all;
  writing-mode: horizontal-tb;
  opacity: 1;
}
.content .name {
  font-size: 2.2rem;
  font-weight: bold;
}
.content .occupation {
  font-size: 0.875rem;
}
.font20 {
  font-size: 1.25rem;
}
.info {
  padding-top: 1.25rem;
  color: rgb(0, 0, 0);
}
.info .row {
  margin: 0.375rem 0;
  width: 100%;
  text-align: left;
}
.info .text {
  padding-left: 0.625rem;
  display: inline-block;
}
.info .icon {
  position: relative;
  top: 0.125rem;
}
.btn{
  position: absolute;
  right: .625rem;
  bottom: .5rem;
  z-index: 181;
  color: aliceblue;
}
@media screen and (max-width: 990px) {
  .product-grid {
    margin: 0 auto;
  }
}
</style>
