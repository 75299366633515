<template>
  <div>
    <van-nav-bar
      title="关于我们"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
      <van-cell-group title="" :border="false">
        <van-cell
          value="神笔软件：人人可以轻松获得价值"
        />
        <van-cell
          title="博物馆应用"
          label="https://www.bilibili.com/video/BV1G54y1L74P"
          url="https://www.bilibili.com/video/BV1G54y1L74P"
        />
        <van-cell
          title="机械应用"
          label="https://www.bilibili.com/video/BV1gh411v7MB"
          url="https://www.bilibili.com/video/BV1gh411v7MB"
        />
        <van-cell
          title="城市应用"
          label="https://www.bilibili.com/video/BV1nV411E7Sn"
          url="https://www.bilibili.com/video/BV1nV411E7Sn"
        />
        <van-cell
          title="画展应用"
          label="https://www.bilibili.com/video/BV1Yb4y1f7gn"
          url="https://www.bilibili.com/video/BV1Yb4y1f7gn"
        />
        <van-cell
          title="超市应用"
          label="https://www.bilibili.com/video/BV17b4y1f78y"
          url="https://www.bilibili.com/video/BV17b4y1f78y"
        />
        <van-cell
          title="汽车应用"
          label="https://www.bilibili.com/video/BV17b4y1f78y"
          url="https://www.bilibili.com/video/BV1354y1L7rn"
        />
      </van-cell-group>
    <img width="150" height="150" src="/img/resource/contactme.jpg" />
  </div>
</template>
<script>
export default {
  name: 'UserAgreement',
  data () {
    return {
      company: '神笔科技',
      mobileno: '+86-136888888',
      email: 'sales@yfzne.com',
      website: 'https://www.yfzne.com'
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  },
  mounted () {
    document.title = '联系我们'
  }
}
</script>
<style></style>
