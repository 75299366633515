<template>
  <div></div>
</template>
<script>
import * as THREE from 'three'
import Dexie from 'dexie'
import Worker from './model.worker.js'
export default {
  name: 'ModelStorage',
  inject: {
    Action: {
      name: 'Action',
      default () {
        return null
      }
    }
  },
  data () {
    var db = new Dexie('ModelDatabase')
    db.version(1).stores({
      //  创建表
      modelList: '++id,name,model,edition,animation'
    })
    return {
      db,
      animationAction: [],
      animationMixer: null,
      models: {},
      meshName: 'HDM_01_04_trunk'
    }
  },
  props: {
    edition: Number
  },
  created () {},
  beforeDestroy () {
    this.animationAction = null
    this.animationMixer = null
  },
  methods: {
    /** 线程 保存
     *@param obj: obj保存的对象
     */
    WorkerSave (obj) {
      const worker = new Worker()
      worker.postMessage(obj)
      worker.onmessage = e => {
        console.log('Received message ' + e.data)
        worker.terminate()
      }
    },
    /** 该方法用于读取数据库模型文件
     *@param modelName: 数据库存储的模型 名字
     **/
    async readModel (modelName) {
      let dbmodel = null
      await this.db.modelList
        .where('name')
        .equalsIgnoreCase(modelName)
        .first()
        .then(res => {
          if (res) {
            if (res.edition !== this.edition) {
              this.db.modelList.delete(res.id)
              dbmodel = null
            } else {
              //  返回模型
              dbmodel = res
            }
          } else {
            //  加载模型文件
            dbmodel = null
          }
        })
        .catch(err => {
          console.log('数据读取失败', err)
          dbmodel = null
        })

      return dbmodel
    },
    getModel (model) {
      model.forEach(items => {
        //  HDM_01_04_trunk
        if (items.name === this.meshName) {
          this.models = items
          return items
        } else {
          return this.getModel(items.children)
        }
      })
    },
    /** 加载模型动画
     * @param animations: 动画数据
     * @param obj: 模型数据
     */
    animation (animations, obj) {
      if (!this.animationAction) {
        this.animationAction = []
      }
      this.needsUpdate = true
      if (animations && animations.length) {
        const mixer = new THREE.AnimationMixer(obj)
        for (let i = 0; i < animations.length; i++) {
          const animation = animations[i]
          //  There's .3333 seconds junk at the tail of the Monster animation that
          //  keeps it from looping cleanly. Clip it at 3 seconds
          //  animation.duration = 3;
          //  保存动画 ACtion
          this.animationAction.push(mixer.clipAction(animation))
        }
        this.animationMixer = mixer
      }
      if (this.Action) {
        this.Action.animationAction = this.animationAction
        this.Action.mixer = this.animationMixer
        console.log('Action OK')
      }
    },
    /** 初始化函数
     * @param fileName : 文件名称
     */
    initial (fileName) {
      //  查看数据有没有 模型
      // 读取数据库
      const isbase = this.isBase64(fileName)
      if (isbase) {
        this.loadFile().then(res => {
          this.requireModel(res)
        }).catch(() => {
          this.$emit('loadErro')
        })
      } else {
        this.readModel(fileName).then((res) => {
          if (res) {
            this.requireModel(res.model)
          } else {
            this.loadFile().then(res => {
              const model = {
                name: fileName,
                model: res,
                edition: this.edition
              }
              console.log('WorkerSave')
              this.WorkerSave(model)
              console.log('requireModel')
              this.requireModel(res)
            }).catch(() => {
              this.$emit('loadErro')
            })
          }
        })
      }

      // new Promise(resolve => {
      //   this.readModel(fileName).then(async dbmodel => {
      //     if (dbmodel) {
      //       //  解析模型  console.log('解析模型')
      //       await this.loaderModel(dbmodel).then(newmodel => {
      //         this.process()
      //         resolve(newmodel)
      //       })
      //     } else {
      //       //  重新加载模型  console.log('重新加载模型')
      //       await this.requireModel().then(newmodel => {
      //         this.process()
      //         resolve(newmodel)
      //       })
      //     }
      //   })
      // }).then(model => {
      //   //  把动画对象 传给 ACtion
      //   if (this.Action) {
      //     this.Action.animationAction = this.animationAction
      //     this.Action.mixer = this.animationMixer
      //   }
      // })
    },
    /**
     * 加载 文件model
     */
    requireModel () {},
    /**
     * 请求文件
     */
    loadFile () {
      const loader = new THREE.FileLoader()
      loader.setResponseType('arraybuffer')
      const _this = this
      // 加载一个文本文件，并把结果输出到控制台上
      return new Promise((resolve, reject) => {
        loader.load(
          // resource URL
          this.url,

          // onLoad回调
          function (data) {
            // output the text to the console
            // console.log(data)
            resolve(data)
          },

          // onProgress回调
          function (xhr) {
            console.log(xhr)
            let progress = 100
            if (xhr.total !== 0) {
              progress = (xhr.loaded / xhr.total) * 100
            }
            console.log(progress + '% loaded')
            _this.$emit('progress', progress)
          },

          // onError回调
          // eslint-disable-next-line handle-callback-err
          function (err) {
            console.error('An error happened')
            reject(err)
          }
        )
      })
    },
    /**
     * base64 数据判断
     */
    isBase64 (string) {
      if (string.length > 100) {
        var str = string.substring(0, 100)
      }
      var reg = /^\s*data:*(;base64)?,*/i
      const bool = reg.test(str)
      console.log('bool', bool)
      if (bool) {
        console.log('bool:ture 如果是base64格式输出该base64')
      }
      return bool
    }
  }
}
</script>

<style></style>
