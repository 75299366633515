<template>
  <div class="Load">
    <div class="percentage">
      <van-progress :percentage="percentage"  />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadAnimation2',
  props: {
    percentage: {
      type: [Number, String],
      default () {
        return 10
      }
    }
  }
}
</script>

<style scoped>
.Load{
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items:center ;
  align-content:center;
  background: radial-gradient(50% 50% at 0 0,rgba(255, 255, 255, 0.1),rgba(173, 173, 173, 0.589),rgba(255, 255, 255, 0.17));
  background: -webkit-radial-gradient(50% 50%,rgba(255, 255, 255, 0.1),rgba(173, 173, 173, 0.589),rgba(255, 255, 255, 0.17));
  background: -moz-radial-gradient(50% 50%,rgba(255, 255, 255, 0.1),rgba(173, 173, 173, 0.589),rgba(255, 255, 255, 0.17));
}
.percentage{
  width: 30%;
  margin: 0 auto;
  /* left: 0;top: 0;right: 0;bottom: 0;
  margin: auto; */
}
</style>
