import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {

    UserInfo: {

    },
    editingCard: {},
    collectingCardId: '',
    jsApiTicket: ''
  },
  mutations: {

    setUserInfo (state, val) {
      Object.assign(state.UserInfo, val)
    },

    setEditingCard (state, val) {
      state.editingCard = val
    },
    setCollectingCardId (state, val) {
      state.collectingCardId = val
    },
    setJsApiTicket (state, val) {
      state.jsApiTicket = val
    }
  },
  actions: {
  },
  modules: {
  },
  strict: debug,
  plugins: [createPersistedState()]
})
