<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'Login'
}
</script>

<style>

</style>
