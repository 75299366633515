<template>
  <div>
    <object3d :position="position" :rotation="rotation" :name="name">
      <mesh :options="Mshoptions" :scale="scale"  name="world">
        <animation-action >
          <m-gltf @getModels="getModels" @progress="progress" @loadingOver="loadingOver" @loadErro="loadErro" :url="modelSrc" :edition="edition">
          </m-gltf>
        </animation-action>
        <!-- <animation-action v-if="getFileType(modelSrc, 'fbx')">
          <m-fbx :fbx-url="modelSrc" :process="process" :edition="edition">
          </m-fbx>
        </animation-action> -->
      </mesh>
    </object3d>
  </div>
</template>

<script>
export default {
  name: 'model',
  props: {
    name: {
      type: String,
      default () {
        return ''
      }
    },
    position: {
      type: Object,
      default () {
        return { x: 0, y: 0, z: 0 }
      }
    },
    rotation: {
      type: Object,
      default () {
        return { x: 0, y: 0, z: 0 }
      }
    },
    modelSrc: {
      type: String,
      default () {
        return ''
      }
    },
    renderorder: {
      type: Number,
      default () {
        return 0
      }
    },
    edition: {
      type: Number,
      default () {
        return 1
      }
    },
    scale: {
      type: Number,
      default () {
        return 1
      }
    }
  },
  data () {
    return {
      loaderorder: 0,
      play: true,
      num: 1,
      clampWhenFinished: true,
      Mshoptions: {
        receiveShadow: true, /// / 平面接收阴影,
        castShadow: true // 立方体的阴影
      },
      time: 0,
      timeScale: 1
    }
  },
  watch: {},
  mounted () {

  },
  methods: {
    progress (val) {
      this.$emit('progress', val)
    },
    loadingOver () {
      this.$emit('loadingOver')
    },
    loadErro () {
      this.$emit('loadErro')
    },
    /** 返回文件 后缀名 */
    getFileType (filePath, fileName) {
      var startIndex = filePath.lastIndexOf('.')
      if (startIndex !== -1) {
        var name = filePath
          .substring(startIndex + 1, filePath.length)
          .toLowerCase()
        return name === fileName
      } else {
        return ''
      }
    },
    getModels (model) {
      // console.log('meeeeee', model)
      this.$emit('getModels', model)
    }
  },
  beforeDestroy () {
    this.loaderorder = 0
  }
}
</script>

<style></style>
