export default {
  autoGraph: {
    type: 'text',
    id: 'autoGraph',
    label: '名字',
    value: '个性签名'
  },
  companyAddress: {
    type: 'text',
    id: 'companyAddress',
    label: '公司地址',
    value: '公司地址',
    iconShow: true,
    iconClass: ['icon'],
    iconName: 'map-marked',
    textClass: ['marked', 'text']
  },
  companyName: {
    type: 'text',
    id: 'companyName',
    label: '公司名字',
    value: '公司名字',
    iconShow: true,
    iconClass: ['icon'],
    iconName: 'phone',
    textClass: ['phone', 'text']
  },
  email: {
    type: 'text',
    id: 'email',
    label: '邮件',
    value: '邮件',
    iconShow: true,
    iconClass: ['icon'],
    iconName: 'envelop-o',
    textClass: ['chat', 'text']
  },
  mobileNo: {
    type: 'text',
    id: 'mobileNo',
    label: '手机号码',
    value: '手机号码',
    iconShow: true,
    iconClass: ['icon'],
    iconName: 'phone',
    textClass: ['phone', 'text']
  },
  officePosition: {
    type: 'text',
    id: 'officePosition',
    label: '职位',
    value: '职位',
    rowClass: { row: false },
    iconShow: false,
    iconClass: ['icon'],
    iconName: 'phone',
    textClass: ['font20', 'textTitle', 'occupation']
  },
  phoneNo: {
    type: 'text',
    id: 'phoneNo',
    label: '电话号码',
    value: '电话号码',
    iconShow: true,
    iconClass: ['icon'],
    iconName: 'phone',
    textClass: ['phone', 'text']
  },
  userName: {
    type: 'text',
    id: 'userName',
    label: '名字',
    value: '名字',
    rowClass: { row: false },
    iconShow: false,
    iconClass: ['icon'],
    iconName: 'phone',
    textClass: ['textTitle', 'name']
  }
}
