<template>
  <!-- 主页 -->
  <div class="home">
    <div class="message" :class="titleClass">
      <van-row>
        <van-col span="24">
          <div class="setting" @click="changeInfo=!changeInfo">
            <van-icon name="setting-o" />
          </div>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="16">
          <div class="info_user">
            <div class="info-name">
              {{ UserInfo.userName }}
            </div>
            <div class="info-Autograph">
              {{ UserInfo.autoGraph }}
            </div>
          </div>
        </van-col>
        <van-col span="8">
          <div class="user_portrait">
            <van-image
              round
              width="5rem"
              height="5rem"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            />
          </div>
        </van-col>
      </van-row>
      <div style="height:20px"></div>
      <van-row >
        <van-col span="24">
          <div class="vip">
            <div class="vip_img">
              <van-icon v-if="UserInfo.vipLevel === 0"  size="30" class-prefix="iconfont" name="huiyuan"></van-icon>
              <van-icon v-if="UserInfo.vipLevel === 1"  size="30" class-prefix="iconfont" name="vip"></van-icon>
              <van-icon v-if="UserInfo.vipLevel === 2"  size="30" class-prefix="iconfont" name="svip"></van-icon>
            </div>
            <div v-if="UserInfo.vipLevel === 0" class="vip_text" @click="purchaseVip">购买VIP/SVIP</div>
            <div v-if="UserInfo.vipLevel === 1" class="vip_text" @click="purchaseVip">续费VIP/购买SVIP</div>
            <div v-if="UserInfo.vipLevel === 2" class="vip_text" @click="purchaseVip" >续费SVIP</div>
            <div class="vip_date" v-if="UserInfo.vipLevel > 0" >
              <span>2021-02-15</span>到<span>2021-12-18</span>
            </div>
          </div>
        </van-col>
      </van-row>

      <div style="height:20px"></div>
    </div>
    <div class="user-info-show" v-show="!changeInfo">
      <van-row>
        <van-col span="10"><div class="title text">手机 :</div></van-col>
        <van-col span="14"> <div class="info_text phone text">{{UserInfo.mobileNo}}</div></van-col>
      </van-row>
      <van-row>
        <van-col span="10"><div class="title text">固定电话 :</div></van-col>
        <van-col span="14"> <div class="info_text text">{{UserInfo.phoneNo}}</div></van-col>
      </van-row>
      <van-row>
        <van-col span="10"><div class="title text">邮箱 :</div></van-col>
        <van-col span="14"> <div class="info_text text">{{UserInfo.email}}</div></van-col>
      </van-row>

      <van-row>
        <van-col span="10"><div class="title text">公司 :</div></van-col>
        <van-col span="14"> <div class="info_text text">{{UserInfo.companyName}}</div></van-col>
      </van-row>
      <van-row>
        <van-col span="10"> <div class="title text">职位 :</div></van-col>
        <van-col span="14"> <div class="info_text text">{{UserInfo.officePosition}}</div></van-col>
      </van-row>
      <van-row>
        <van-col span="10"> <div class="title text">公司地址 :</div></van-col>
        <van-col span="14"> <div class="info_text text">{{UserInfo.companyAddress}}</div></van-col>
      </van-row>
      <div class="info-btn">
<!--        <van-cell title="点击了解更多" is-link  @click="openPage"></van-cell>-->
        <van-button @click="openPage" round block plain>点击了解更多</van-button>
      </div>
    </div>
    <div class="user-info" v-show="changeInfo">
      <div class="user_title">个人信息</div>
      <van-cell-group>
        <van-field v-model="info.userName" label="名字" placeholder="请输入名字" />
        <van-field
          v-model="info.autoGraph"
          label="个性签名"
          placeholder="请输入你的个性签名"
        />
        <van-field v-model="info.mobileNo" label="手机" placeholder="请输入手机号" readonly />
        <van-field v-model="info.phoneNo" label="固定电话" placeholder="请输入电话号码" />
        <van-field v-model="info.email" label="邮箱" placeholder="请输入邮箱" />

      </van-cell-group>
      <div class="user_title">公司信息</div>
      <van-cell-group>
        <van-field v-model="info.companyName" label="公司" placeholder="请输入公司名字" />
        <van-field v-model="info.officePosition" label="职位" placeholder="请输入职位" />
        <van-field v-model="info.companyAddress" label="公司地址" placeholder="请输入公司地址" />
      </van-cell-group>
<!--      <div class="user_title">公司简介</div>-->
<!--      <van-cell-group>-->
<!--        <van-field-->
<!--          v-model="info.companyProfile"-->
<!--          rows="2"-->
<!--          autosize-->
<!--          type="textarea"-->
<!--          maxlength="50"-->
<!--          placeholder="请输入公司信息"-->
<!--          show-word-limit-->
<!--        />-->
<!--      </van-cell-group>-->
      <div class="save-btn">
        <van-button type="primary" round @click="saveInfo"  block>保存信息</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'

export default {
  name: 'UserInfo',
  data () {
    return {
      changeInfo: false,
      info: {
      },
      titleClass: {
        baby_blue: true
      }
    }
  },
  computed: {
    UserInfo: {
      get () {
        return this.$store.state.UserInfo
      }
    }
  },
  mounted () {
    this.info = JSON.parse(JSON.stringify(this.UserInfo))
    if (this.UserInfo.vipLevel === 0) {
      this.titleClass = {
        baby_blue: true
      }
    } else if (this.UserInfo.vipLevel === 1) {
      this.titleClass = {
        vip_color: true
      }
    } else if (this.UserInfo.vipLevel === 2) {
      this.titleClass = {
        svip_color: true
      }
    }
  },
  methods: {
    purchaseVip () {
      this.$router.push({ path: '/PurchaseVip' })
    },
    saveInfo () {
      this.$store.commit('setUserInfo', this.info)
      console.log('this.info', this.info)
      var fd = new FormData()
      fd.append('req', JSON.stringify(this.info))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/user/completeInfo', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            Toast.success('更新成功')
          } else if (res.data.returnCode === '003') {
            Toast.fail('用户状态错误')
            this.$router.push({ path: '/Login' })
          } else if (res.data.returnCode === '001') {
            Toast.fail('用户信息错误')
          } else {
            Toast.fail('保存失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })

      this.changeInfo = false
    },
    openPage () {
      this.$router.push({ path: '/contactme' })
    }
  },

  components: {}
}
</script>
<style scoped>
  .home {
    background-color: rgb(244 244 244);
    min-height: 93.5vh;
  }
  .baby_blue {
    background: rgb(0, 204, 255);
  }
  .vip_color{
    font-size: 20px;
    -webkit-mask-image: -webkit-gradient(linear, left bottom, right top,  color-stop(darkgray),  color-stop(silver),  color-stop(lightgray));
    background: silver;
    -webkit-text-fill-color: #16181b;
    -webkit-background-size: 200% 100%;
    animation: bgp 5s infinite linear;
  }

  .svip_color{
    font-size: 20px;
    -webkit-mask-image: -webkit-gradient(linear, left bottom, right top,  color-stop(goldenrod),  color-stop(gold),  color-stop(yellow));
    background-image: linear-gradient(to right top, #d0c06b, #ceae61, #d7c273, gold);
    -webkit-text-fill-color: #16181b;
    -webkit-background-size: 200% 100%;
    animation: bgp 5s infinite linear;
  }

  .message {
    padding: 0 15px;
  }
  .info_user {
    color: aliceblue;
    height: 80px;
    padding-left: 0.625rem;
  }
  .info_user .info-name {
    height: 3.75rem;
    line-height: 3.75rem;
    font-family: 微软雅黑;
    font-size: 1.875rem;
    font-weight: 600;
  }
  .info_user .info-Autograph {
    height: 1.25rem;
    line-height: 1.25rem;
    font-family: 微软雅黑;
    font-size: 0.625rem;
  }
  .user_portrait {
    text-align: right;
  }
  /* 用户信息展示 */
  .user-info-show {
    padding: 2.25rem 4rem 1.875rem;
    background-color: rgb(244 244 244);
  }
  .user-info-show .text {
    font-family: "Microsoft YaHei",sans-serif;
    font-size:1rem ;
    font-weight: normal;
    color: #666666;
    padding: 5px 10px;
  }
  .user-info-show .title {
    text-align: left;
  }
  .user-info-show .info_text {
    font-size: 1rem;
    text-align: left;
  }

  /* 名片 */

  .vip {
    padding-left: .9375rem;
  }
  .vip_img{
    display: inline-block;
    width: 40px;
    height: 30px;
  }
  .vip_img img{
    width: 100%;
    position: relative;
    top: .9375rem;
  }

  .vip_text{
    display: inline-block;
    font-size: 1rem;
    line-height: 30px;
    padding-left: 5px;
    transform: scale(0.8);
    color: #1989fa;
    text-decoration:underline
  }
  .vip_date{
    display: inline-block;
    font-size: .75rem;
    line-height: 30px;
    padding-left: 5px;
    transform: scale(0.8);
    color: #88751f;
  }

  .user_title {
    background: rgb(247 248 250);
    margin: 0;
    padding: 20px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
  /* 我的名片 */
  .card-box {
    background: #ddd;
    width: 90vmin;
    height: 300px;
    margin: 0 auto;
  }
  /* 设置 */
  .setting {
    font-size: 25px;
    text-align: right;
    padding-right: 0px;
    padding-top: 0.625rem;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 0.3125rem;
  }
  /* save-btn */
  .save-btn{
    padding: 1.3125rem 15px;
    margin-bottom: 20px;
  }
  .info-btn{
    padding: 1.3125rem 15px;
    width: 90vw;
    position: absolute;
    bottom: 4rem;
    right: 5px;
  }
  .phone{
    position: relative;
    top: .075rem;
  }
</style>
