<template>
  <div id="dom" class="title-list" style="overflow: hidden">
    <div class="title-list-item" v-for="(item, i) in shopItem" :key="i">
      <img class="img" src="static/img/Visits.png" width="18px" height="18px" />
      <span class="title">{{ item }}</span>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import Object3D from './../components/Object3D'
export default {
  name: 'CssImg',
  mixins: [Object3D],
  props: {
    size: {
      type: Object, // { w, h }
      default () {
        return { w: 50, h: 50 }
      }
    },
    src: {
      type: String,
      default () {
        return ''
      }
    },
    show: {
      type: Boolean,
      default () {
        return true
      }
    },
    shopItem: {
      type: Array,
      default () {
        return null
      }
    },
    titleId: {
      type: Number,
      default () {
        return -1
      }
    }
  },
  data () {
    const curObj = new THREE.Group()
    return { curObj }
  },
  mounted () {
    const dom = document.getElementById('dom')
    const object = new CSS2DObject(dom)
    object.scale.set(50, 50, 1)
    this.curObj.add(object)
  },
  methods: {
    removeDoms () {
      const dom = document.getElementById('dom')
      dom.style.width = '0'
    }
  },

  watch: {
    show (newShow) {
      if (newShow) {
        this.dom.style.width = `${this.size.w}px`
        this.dom.style.height = `${this.size.h}px`
      } else {
        this.dom.style.width = '0px'
        this.dom.style.height = '0px'
      }
    },
    titleId (val) {
      const dom = document.getElementById('dom')
      if (val < 0) {
        dom.style.width = '0'
      } else {
        if (window.screen.width < 960) {
          dom.style.width = '550px'
        } else {
          dom.style.width = '700px'
        }
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#dom {
  /* zoom: 12%; */
}
.title-list {
  width: 280px;
  display: inline-block;
  overflow: auto;
  padding-bottom: 25px;
  border-radius: 5px;
}
.title-list-item {
  color: #1e1e1e;
  /* transform: scale(.9); */
  font-size: 18px;
  padding: 0;
  zoom: 120%;
  display: inline-block;
  position: relative;
  min-width: 215px;
}
.title {
  display: inline-block;
  margin-left: 5px;
  min-width: 50px;
}
.img {
  margin-right: 10px;
  transform: translateY(5px);
}
@media only screen and (max-width: 960px) {
  .title-list-item {
    min-width: 200px;
    zoom: 100%;
    display: inline-block;
    /* transform: scale(.9); */
  }
  .img {
    transform: translateY(3px);
    margin-right: 5px;
  }
}
/*.title-list-item:nth-child(3n){*/
/*    margin-left: 18rem;*/
/*}*/
.lines {
  width: 10px;
  height: 10px;
  background-color: #0c9a9a;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transform: translateY(20%);
}
</style>
