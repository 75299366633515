<template>
  <div>
    <van-nav-bar
      title="登录"
       right-text="注册"
      :left-arrow="false"
       @click-right="onClickRight"
    />
    <div style="width:100%;height:30px;"></div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        label="手机号"
        name="validator"
        placeholder="手机号"
        :rules="[{ pattern, message: '请填写正确手机号' }]"
      />
      <van-field
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button>
          <van-button
            @click="SendVerification"
            :disabled="disabledSms"
            size="small"
            type="primary"
          >
            <div class="time">
              <div>发送验证码</div>
              <van-count-down
                v-show="disabledSms"
                ref="countDown"
                millisecond
                format="ss"
                :auto-start="false"
                @finish="finish"
                :time="time"
              ></van-count-down>
            </div>
          </van-button>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">登录</van-button>
      </div>
    </van-form>
    <div class="row">
      <div class="phone_login" @click="Jump_pwdLogin">账号密码登录</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
export default {
  name: 'userPhoneLogin',
  data () {
    return {
      phone: '',
      sms: '',
      pattern: /^1[0-9]{10}$/,
      time: 60000,
      disabledSms: false
    }
  },
  methods: {
    onSubmit () {
      const phone = this.phone
      const vCode = this.sms
      const user = {
        mobileNo: phone,
        verifyCode: vCode
      }
      console.log('this', user)
      var fd = new FormData()
      fd.append('req', JSON.stringify(user))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/user/otp-login', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.$store.commit('setUserInfo', res.data.userInfo)
            this.collectCardIfChecked()
            this.$router.push({ path: '/' })
          } else if (res.data.returnCode === '003') {
            Toast.fail('用户不存在')
          } else if (res.data.returnCode === '004') {
            Toast.fail('验证码错误')
          } else {
            Toast.fail('登录失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    onClickRight () {
      Toast('注册')
      this.$router.push({ path: '/Login/RegisterPassword' })
    },
    SendVerification () {
      this.start()
      const fd = new FormData()
      fd.append('phoneNo', this.phone)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/sms/loginCode', fd, config)
        .then(res => {
          console.log(res)
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
      this.disabledSms = true
    },
    collectCardIfChecked () {
      const cardId = this.$store.state.collectingCardId
      if (cardId) {
        const req = {
          encryptCardId: cardId
        }

        const fd = new FormData()
        console.log('req', req)
        fd.append('req', JSON.stringify(req))
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios
          .post('/card/collect', fd, config)
          .then(res => {
            console.log(res)
            if (res.data.returnCode === '0') {
              this.$store.commit('setCollectingCardId', '')
            } else if (res.data.returnCode === '3') {
              Toast.fail('用户状态异常')
            } else if (res.data.returnCode === '1') {
              Toast.fail('无法收藏自己的名片')
            } else if (res.data.returnCode === '2') {
              Toast.fail('名片已经被收藏')
            } else {
              Toast.fail('收藏名片失败')
            }
          })
          .catch(res => {
            console.log(res)
            Toast.fail('网络错误')
          })
      }
    },
    start () {
      this.$refs.countDown.start()
    },
    reset () {
      this.$refs.countDown.reset()
    },
    finish () {
      this.disabledSms = false
      this.reset()
    },
    Jump_pwdLogin () {
      this.$router.push('/Login')
    }
  }
}
</script>

<style scoped>
.time {
  display: flex;
}
.time >>> .van-count-down {
  color: aliceblue;
  font-size: 12px;
  line-height: 1;
}
.row {
  margin-top: 0.9375rem;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  line-height: 20px;
  color: #111;
  font-family: PingFangSC-regular;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
</style>
