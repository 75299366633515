import { render, staticRenderFns } from "./CardTemplateList.vue?vue&type=template&id=52b63774&scoped=true&"
import script from "./CardTemplateList.vue?vue&type=script&lang=js&"
export * from "./CardTemplateList.vue?vue&type=script&lang=js&"
import style0 from "./CardTemplateList.vue?vue&type=style&index=0&id=52b63774&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b63774",
  null
  
)

export default component.exports