<template>
  <div class="demo">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="product-grid" id="card">
            <div class="scene">
               <background :modelSrc="attr.modelSrc0.value"></background>
            </div>
            <div class="product-image">
            </div>
            <div class="product-content">
              <h3 class="title" contenteditable="true" :id="attr.title0.id" >{{attr.title0.value}}</h3>
              <div class="price" contenteditable="true" :id="attr.price0.id">{{attr.price0.value}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="product-grid">
             <div class="scene">
               <background :modelSrc="attr.modelSrc1.value"></background>
             </div>
            <div class="product-image">

            </div>
           <div class="product-content">
              <h3 class="title" contenteditable="true" :id="attr.title1.id" >{{attr.title1.value}}</h3>
              <div class="price" contenteditable="true" @input="change" :id="attr.price1.id">{{attr.price1.value}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'productTemplateVip01',
  props: {
    attr: {
      type: Object,
      default () {
        return {}
      }
    },
    editStatus: {
      type: String,
      default () {
        return 'false'
      }
    }
  },
  methods: {
    change () {
      console.log('sgfagfdgds')
    }
  }
}
</script>

<style scoped>
.col-md-3 {
  position: relative;
  min-height: 1px;
}
.col-sm-6 {
  position: relative;
  min-height: 1px;
}
.container {
  margin-right: auto;
  margin-left: auto;
}
.row{
   width: 22.5rem;
   margin: 0 auto;
}
.demo {
  padding: 50px 5px;
  background: #fff;
}
.product-grid {
  width: 22.5rem;
  position: relative;
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif;
}
.scene{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 33;
  width: 100%;
  height: 100%;
}
.product-grid .product-image {
  width: 22.5rem;
  height: 13.75rem;
  position: relative;
  overflow: hidden;
  box-shadow: inset -1px 0px 20px 10px #ccf1fe;
}

.product-grid .product-content {
  padding: 12px 0 0;
  position: relative;
  z-index: 44;
}
.product-grid .title {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 5px;
}
.product-grid .title a {
  color: #333;
  transition: all 0.5s ease;
}
.product-grid .title a:hover {
  color: #b79b6c;
}
.product-grid .price {
  color: #b79b6c;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 990px) {
  .product-grid {
    margin: 0 0 30px;
  }
}
</style>
