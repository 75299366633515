<template>
  <div>
    <van-nav-bar title="名片夹" :left-arrow="false" :fixed="true" :placeholder="true" />
    <div class="card">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getHoldList">
      <van-grid :column-num="1">
        <van-grid-item  v-for="(item,i) in list" :key="i">
           <van-swipe-cell>
            <van-cell :border="false" @click="OpenUserCard(item)">
              <div class="card-img">
                <van-image :src="item.thumbnail">
<!--                  <template v-slot:loading>-->
<!--                    <div class="loading">-->
<!--                      <div class="position">-->
<!--                          <p><van-loading color="rgb(25 137 250)"  text-color="rgb(25 137 250)" type="spinner" size="50" >名片加载中...</van-loading></p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->
                  <template v-slot:error>
                    <div class="error">
                      <van-empty image="network" description="名片消失了/(ㄒoㄒ)/~~" />
                    </div>
                  </template>
                </van-image>
              </div>
            </van-cell>
            <template #right>
              <van-button   icon="delete-o"  type="info"  class="btn"  text="删除" @click="deleteCard(item, i)"/>
            </template>
          </van-swipe-cell>
        </van-grid-item>

      </van-grid>
      </van-list>
      <van-empty v-if="list.length === 0"
                 class="custom-image"
                 image="/img/resource/no_content.png"
                 description="还有没有名片？赶紧找朋友收一个吧"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
export default {
  name: 'UserReceiveCard',
  data () {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 5,
      loading: false,
      finished: false
    }
  },
  mounted () {
    this.pageNumber = 1
    this.list = []
  },
  methods: {
    OpenUserCard (item) {
      console.log('card open item', item)
      this.$router.push('/PreviewCard/PreviewReceivedCard/' + encodeURIComponent(item.encryptId))
    },
    getHoldList () {
      const req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }

      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/card/getCardHolder', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.loading = false
            if (res.data.cardInfoList.length < 5) {
              this.finished = true
            } else {
              this.pageNumber += 1
            }
            if (res.data.cardInfoList.length > 0) {
              this.list = this.list.concat(res.data.cardInfoList)
            }
          } else if (res.data.returnCode === '003') {
            Toast.fail('用户状态错误')
            this.$router.push({ path: '/Login' })
          } else if (res.data.returnCode === '001') {
            Toast.fail('参数错误')
          } else {
            Toast.fail('获取列表失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    },
    deleteCard (item, i) {
      const req = {
        encryptCardId: item.encryptId
      }
      const fd = new FormData()
      console.log('req', req)
      fd.append('req', JSON.stringify(req))
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/card/deleteCardHolder', fd, config)
        .then(res => {
          console.log(res)
          if (res.data.returnCode === '0') {
            this.list.splice(i, 1)
            console.log('this.list', this.list)
          } else if (res.data.returnCode === '003') {
            Toast.fail('用户状态错误')
            this.$router.push({ path: '/Login' })
          } else if (res.data.returnCode === '001') {
            Toast.fail('参数错误')
          } else {
            Toast.fail('获取列表失败')
          }
        })
        .catch(res => {
          console.log(res)
          Toast.fail('网络错误')
        })
    }
  }
}
</script>

<style scoped>
.card >>> .btn {
  height: 100%;
  border: none;
  outline: none;
  background: none;
  color: #111;
}
/* .card >>> */
.card >>> .van-cell {
  padding: 10px 0;
}
.card-share {
  position: absolute;
  right: 0.625rem;
  bottom: 0.5rem;
  font-size: 1.875rem;
  background: #111;
}
.card-img {
  width: 22.5rem;
  height: 13.75rem;
  overflow: hidden;
  text-align: center;
}
.card-img >>> .van-image {
  width: 100%;
  height: 100%;
}
.card >>> .van-grid-item__content {
  padding: 5px;
}
.card >>> .van-grid-item__content:first-child {
  padding: 5px 5px 5px 5px;
}

.error{
  width: 22.5rem;
  position: relative;
  text-align: center;
  z-index: 111;
}

.loading {
  width: 22.5rem;
  position: relative;
  text-align: center;
  margin: 0 auto;
  background-color: #f7f8fa;
}
.loading .position{
  position: absolute;
  right: 0;
  bottom: 45%;
  z-index: 333;
  width: 100%;
}
.loading .position p{
  display: inline-block;
}

.custom-image >>> .van-empty__image {
  width: 16rem;
  height: 14rem;
}
</style>
