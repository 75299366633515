import Login from '../views/Login/Login.vue'
import userLogin from '../views/Login/UserLogin.vue'
import RegisterPassword from '../views/Login/RegisterPassword.vue'
import userPhoneLogin from '../views/Login/UserPhoneLogin.vue'
import ForgetPassword from '../views/Login/ForgetPassword.vue'

const login = {
  path: '/Login',
  name: 'Login',
  component: Login,
  redirect: '/Login/userLogin', // 设置默认打开的页面
  children: [
    {
      path: '',
      name: 'userLogin',
      component: userLogin
    },
    {
      path: 'RegisterPassword',
      component: RegisterPassword
    },
    {
      path: 'userPhoneLogin',
      component: userPhoneLogin
    },
    {
      path: 'ForgetPassword',
      component: ForgetPassword
    }

  ]
}
export default login
